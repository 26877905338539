import { useState } from "react";
import { Typography, Button } from "@material-ui/core";
import EstilosGlobales from "../toremp/EstilosGlobales.jsx";
import { useSpring, animated } from "react-spring";

export default function CardDn(props) {
    const [Hover, setHover] = useState(false);

    const EstiloDivGeneral = useSpring({
        cursor: "pointer",
        transform: Hover ? "translate(0,-10px)" : "translate(0,0px)"
    });

    const EstiloImagen = useSpring({
        top: "50%",
        transform: Hover
            ? "translate(0,-50%)scale(1.6)"
            : "translate(0px,-50%)scale(1.4)",
        config: { duration: 800 },
    });

    const EstiloOverlayImagen = useSpring({
        backgroundColor: "#000",
        top: "0px",
        opacity: Hover ? "0.4" : "0",
        zIndex: "2",
        config:{duration:800}
    });

    const EstiloContBoton = useSpring({
        zIndex: "10",
        bottom: Hover?"40px":"20px",
        opacity:Hover?"1":"0",
        config:{
            duration:400
        }
    });
    return (
        <animated.div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className={props.className}
            style={EstiloDivGeneral}
        >
            <div
                className="Titulo_CardDn border py-2 shadow-sm text-center"
                style={{
                    backgroundColor: EstilosGlobales.colorPrimario,
                    borderRadius: "12px 12px 0 0",
                }}
            >
                <Typography
                    className="font-weight-bold text-white"
                    variant="h5"
                >
                    {props.titulo} 
                </Typography>
                <Typography
                    className="font-weight-light"
                    variant="h6"
                    style={{ color: "#444444" }}
                >
                    {props.subtitulo} 
                </Typography>
            </div>
            <div
                className="Cont_CardDn shadow"
                style={{ borderRadius: "0 0 12px 12px" }}
            >
                <div
                    className="position-relative overflow-hidden"
                    style={{ minHeight: "300px" }}
                >
                    <animated.img
                        className="img-fluid position-absolute"
                        src={
                            props.imagen 
                        }
                        style={EstiloImagen}
                        alt="..."
                    />
                    <animated.div
                        className="position-absolute w-100 h-100"
                        style={EstiloOverlayImagen}
                    ></animated.div>
                    <animated.div
                        className="position-absolute w-100 text-center"
                        style={EstiloContBoton}
                    >
                        <Button
                            className="text-white px-5"
                            variant="contained"
                            style={{
                                backgroundColor: EstilosGlobales.colorPrimario,
                            }}
                        >
                            {props.botonTexto}
                        </Button>
                    </animated.div>
                </div>
            </div>
        </animated.div>
    );
}
