import axios from "axios";
import { useEffect, useState } from "react";
import Titulo from "../Titulo";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import CardDn4 from "../CardDn4";

function Esqueleto(props) {
    return (
        <div className={props.className}>
            <Skeleton height={300}></Skeleton>
        </div>
    );
}

export default function CategoriaProductos(props){
    let {capa}=props;

    if(capa==="superfamilia"){
        return(
            <CapaSuperfamilia/>
        );
    }else if(capa==="familia"){
        return(
            <CapaFamilia/>
        );
    }else if(capa==="subfamilia"){
        return(
            <CapaSubfamilia/>
        );
    }else{
        return(
            <></>
        );
    }
}

function CapaSuperfamilia(){
    const [Estado, setEstado] = useState("inicio");
    const [Superfamilias, setSuperfamilias] = useState([{}]);
    const ClaseSuperfamilia = "col-6 col-md-4 col-lg-4 col-xl-4 my-2";

    useEffect(() => {
        if (Estado === "inicio") {
            cargarSuperfamilias();
        }
    });

    const cargarSuperfamilias = () => {
        setEstado("cargando");
        axios({
            url:
                process.env.PUBLIC_URL + "/assets/api/superfamilias_cargar.php",
        }).then((resp) => {
            var res = resp.data;
            setEstado("cargado");
            setSuperfamilias(res);
        });
    };

    return (
        <div className="my-3">
            <Titulo titulo="CATEGORÍA DE PRODUCTOS"></Titulo>
                {Estado === "cargado" && Superfamilias.length>0 ? (
                <div className="row my-2">
                    {Superfamilias.map((superfamilia, index) => {
                        return (
                            <div key={index} className={ClaseSuperfamilia}>
                                <Link
                                    className="text-decoration-none"
                                    to={
                                        "/superfamilias/" +
                                        encodeURIComponent(superfamilia.superfamilia)
                                    }
                                >
                                    <CardDn4
                                        titulo={superfamilia.superfamilia}
                                        imagen={ process.env.PUBLIC_URL+"/assets/imagenes/superfamilias/"+superfamilia.imagen} 
                                    ></CardDn4>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="row">
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                </div>
            )}
        </div>
    );
}

function CapaFamilia(){
    const [Cargado,setCargado] = useState(false);
    const [Familias,setFamilias] = useState([]);
    const ClaseFamilia = "col-6 col-md-4 col-lg-4 col-xl-4 my-2";

    useEffect(() => {
        const cargarFamilias = () => {
            var datos = new FormData();
            datos.append("accion","cargar_familias");
            axios({
                url:
                    process.env.PUBLIC_URL + "/assets/api/familias_cargar.php",
                method:"post",
                data:datos
            }).then((resp) => {
                var res = resp.data;
                setFamilias(res);
                setCargado(true);
            });
        };
        cargarFamilias();
    },[]);

    

    return (
        <div className="my-3">
            <Titulo titulo="CATEGORÍA DE PRODUCTOS"></Titulo>
                {Cargado? (
                <div className="row my-2">
                    {Familias.map((familia, index) => {
                        return (
                            <div key={index} className={ClaseFamilia}>
                                <Link
                                    className="text-decoration-none"
                                    to={
                                        "/productos/" +
                                        encodeURIComponent(familia.superfamilia)+"/"+
                                        encodeURIComponent(familia.familia)+"/"+
                                        "imp"+"/"+
                                        "familia"
                                    }
                                >
                                    <CardDn4
                                        titulo={familia.familia}
                                        imagen={ process.env.PUBLIC_URL+"/assets/imagenes/familias/"+familia.imagen} 
                                    ></CardDn4>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="row">
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                    <Esqueleto className={ClaseFamilia}></Esqueleto>
                </div>
            )}
        </div>
    );
}

function CapaSubfamilia(){
    return (
        <div className="">
        </div>
    );
}
