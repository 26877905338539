import { LinearProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import EstilosGlobales from '../toremp/EstilosGlobales'

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    colorPrimary: {
        background: EstilosGlobales.colorPrimarioContrast
    },
    barColorPrimary:{
        background: EstilosGlobales.colorPrimario
    }
  });

export default function LinearProgressMine(props){
    const classes = useStyles();
    return(
        <LinearProgress className={props.className} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}></LinearProgress>
    );
}
