import { Skeleton } from '@material-ui/lab';
import Producto from '../Producto'
import Titulo from '../Titulo'
import { useState, useEffect } from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import EstilosGlobales from '../../toremp/EstilosGlobales'
import useWindowSize from '../../context/useWindowSize.jsx'



function Esqueleto(props) {
    return (
        <div className={props.className}>
            <Skeleton variant="rect" height={400}></Skeleton>
        </div>
    );
}

export default function ProductosInicio(props) {

    const Settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchMove: false,
        swipeToSlide: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2                    
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1                    
                }
            }
        ]

    };

    const size = useWindowSize();


    const titulo = props.titulo;
    /* var cargado = props.cargado; */
    const ClaseProducto = "col-6 col-md-4 col-lg-3 my-2 px-1 px-md-2";

    const [Estado, setEstado] = useState("inicio");
    const [Productos, setProductos] = useState(props.productos);
    const [PrimerProducto] = useState(props.productos[0]);

    /* const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    }); */


    useEffect(() => {
        if (Estado === "inicio") {
            IniciarComponente();
        } else if (Estado === "cargando") {
            ComponenteCargado();
        }

    })



    const IniciarComponente = () => {
        setEstado("cargando");
        setProductos(Productos.slice(1));
    }

    const ComponenteCargado = () => {
        setEstado("cargado");
    }



    if (Estado==="cargado") {
        return (
            <div className="my-5 px-3">

                <Titulo titulo={titulo}></Titulo>
                <div className="row my-3">
                    <div className={ClaseProducto}>
                        <div className="rounded shadow-sm" style={{
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderColor:EstilosGlobales.colorPrimario 
                        }}>
                            <Producto key={0} DatosProducto={PrimerProducto} className={"ClaseProducto"}></Producto>
                        </div>
                    </div>
                    {
                        parseInt(Productos.length)>2 || parseInt(size.width)<780?
                        <div className="col-6 col-md-8 col-lg-9 my-2 px-1 px-md-2">
                            <Slider {...Settings}>
                                {
                                    Productos.map((producto, index) => {

                                        return (
                                            <div key={index} className="px-sm-2 px-xs-1 px-0">
                                                <Producto DatosProducto={producto} className={"ClaseProducto"}></Producto>
                                            </div>
                                        );

                                    })
                                }
                            </Slider>
                        </div>:
                        <div className="col-6 col-md-8 col-lg-9 my-2 px-1 px-md-2">
                            <div className="row ml-1 justify-content-center">
                                    {
                                        Productos.map((producto, index) => {
                                            return (
                                                <div key={index} className="px-0 px-md-2 col-6 col-md-6 col-lg-4">
                                                    <Producto DatosProducto={producto} className={"ClaseProducto"}></Producto>
                                                </div>
                                            );
                                        })
                                    }
                            </div>
                        </div>
                    }
                </div>

            </div>
        );
    } else {
        return (
            <div className="my-5 px-3">
                <div className="row">
                    <Esqueleto className={ClaseProducto}></Esqueleto>
                    <Esqueleto className={ClaseProducto}></Esqueleto>
                    <Esqueleto className={ClaseProducto}></Esqueleto>
                    <Esqueleto className={ClaseProducto}></Esqueleto>
                </div>
            </div>
        );
    }
}
