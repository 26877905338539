import {useEffect,useState} from 'react'
import axios from 'axios'
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom'
import EstilosGlobales from '../../../toremp/EstilosGlobales'
export default function MenuSuperfamilias(){
    const [Superfamilias,setSuperfamilias] = useState([]);
    useEffect(()=>{
        const cargarSuperfamilias=()=>{
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/superfamilias_cargar.php",
            }).then((resp)=>{
                var res = resp.data;
                setSuperfamilias(res);
                //console.log(res);
            }) 
        } 
        cargarSuperfamilias();
    },[]) 
    return(
        <div className="border-top py-0" style={{backgroundColor:EstilosGlobales.especifico.menuSuperfamilias.fondo}}>
            <div className="container">
                <div className="row justify-content-center">
                    {
                        Superfamilias.map((superfamilia,index)=>{
                            return(
                                <Link key={index} to={"/superfamilias/"+encodeURIComponent(superfamilia.superfamilia)} className="text-decoration-none">
                                    <div style={{padding:"0.5rem"}}>
                                        <Typography variant="caption" style={{fontSize:"11px",color:EstilosGlobales.especifico.menuSuperfamilias.texto}}>
                                            {superfamilia.superfamilia}
                                        </Typography>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div> 
            </div>
        </div>
    );
}
