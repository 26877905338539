import { useState,useEffect } from "react";
import { Card, Typography } from "@material-ui/core";
import Titulo from "../Titulo";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EstilosGlobales from "../../toremp/EstilosGlobales";
import axios from 'axios'

export default function BlogPostSideMenu(props) {
    const [CategoriasBlog, setCategoriasBlog] = useState([
    ]);

    const [UltimosPosts,setUltimosPosts] = useState([
    ]);

    useEffect(()=>{
        const cargarCategoriasBlog=()=>{
            axios({
                url: process.env.PUBLIC_URL+"/assets/api/blog/cargar_categorias.php"
            }).then((resp)=>{
                var res = resp.data;
                //console.log(res);
                setCategoriasBlog(res);
            })
        }   	
        const cargarUltimosPosts=()=>{
            axios({
                url: process.env.PUBLIC_URL+"/assets/api/blog/cargar_ultimos_posts.php"
            }).then((resp)=>{
                var res = resp.data;
                setUltimosPosts(res);
            })
        }
        cargarCategoriasBlog();
        cargarUltimosPosts();
    },[])

    return (
        <Card className={props.className}>
            <div className="py-3 px-3">
                <Titulo size="xsmall">Secciones</Titulo>
                <div className="py-2">
                    {CategoriasBlog.map((categoria, index) => {
                        return (
                            <Link
                                className="d-flex flex-row text-decoration-none"
                                to={"/blog/" + categoria.ID}
                                key={index}
                            >
                                <ArrowRightIcon
                                    fontSize="small"
                                    style={{
                                        color: EstilosGlobales.colorPrimario,
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    className="text-secondary"
                                >
                                    {categoria.nombre}
                                </Typography>
                            </Link>
                        );
                    })}
                </div>
                <Titulo className="mt-3" size="xsmall">
                    Ultimas Publicaciones
                </Titulo>
                <div className="py-2">
                    {UltimosPosts.map((post, index) => {
                        return (
                            <Link
                                className="d-flex flex-row text-decoration-none"
                                to={"/blogpost/" + post.ID}
                                key={index}
                            >
                                <ArrowForwardIosIcon
                                    style={{
                                        fontSize:"10px"
                                    }}
                                    className="mr-1 text-secondary mt-1"
                                />
                                <Typography
                                    variant="body2"
                                    className="text-secondary"
                                >
                                    {post.titulo}
                                </Typography>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </Card>
    );
}
