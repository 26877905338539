import { useState } from "react";
import { Typography, Button, Card } from "@material-ui/core";
import EstilosGlobales from "../toremp/EstilosGlobales.jsx";
import { useSpring, animated } from "react-spring";

export default function CardDn(props) {
    const [Hover, setHover] = useState(false);

    const EstilosContCard = useSpring({
        height: "200px",
        zIndex:"50",
        transform: Hover ? "translate(0,-50px)" : "translate(0,0px)",
    });
    const EstilosBoton = useSpring({
        bottom: "30px",
        position: "absolute",
        opacity: Hover ? "1" : "0",
        config: { duration: 600 },
    });
    const EstiloCapaImagen = useSpring({
        top: "0px",
        zIndex: "20",
        backgroundColor:"#000",
        opacity:Hover?"0.5":"0"
    });
    return (
        <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className={props.className}
        >
            <Card
                className="position-relative overflow-hidden"
                style={{
                    borderRadius: "12px 12px 12px 12px",
                    cursor: "pointer",
                    height: "300px",
                }}
            >
                <div
                    className="ContImagen position-relative overflow-hidden"
                    style={{
                        height: "150px",
                    }}
                >
                    <img
                        className="img-fluid position-relative"
                        src={props.imagen}
                        alt="..."
                        style={{
                            top: "50%",
                            transform: "translate(0,-50%)",
                            zIndex: "10",
                        }}
                    />
                    <animated.div
                        className="position-absolute w-100 h-100"
                        style={EstiloCapaImagen}
                    ></animated.div>
                </div>
                <div className="ContCard">
                    <animated.div
                        className="position-relative bg-white"
                        style={EstilosContCard}
                    >
                        <Typography
                            className="font-weight-bold text-left px-3 pb-2 pt-3"
                            style={{
                                fontSize: "18px",
                                color: EstilosGlobales.colorPrimario,
                            }}
                        >
                            {props.titulo}
                        </Typography>
                        <Typography
                            className="px-3 pb-3 text-secondary"
                            variant="body2"
                        >
                            {props.subtitulo}
                        </Typography>
                        <animated.div
                            className="position-absolute w-100 px-4 text-center"
                            style={EstilosBoton}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                className="text-white w-100"
                                style={{
                                    backgroundColor:
                                        EstilosGlobales.colorPrimario,
                                    borderRadius:"12px 12px 12px 12px"
                                }}
                            >
                                Ver Más
                            </Button>
                        </animated.div>
                    </animated.div>
                </div>
            </Card>
        </div>
    );
}
