import { Typography } from "@material-ui/core";
import { useState } from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';


export default function FichaTecnica(props) {

    const [Seccion, setSeccion] = useState({
        especificaciones: "",
        ficha: "active"
    });    

    let {DatosProducto}=props;

    return (

        <div className={props.className}>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        onClick={() => {
                            setSeccion({ especificaciones: "active", ficha: "" })
                        }}
                        className={"nav-link " + Seccion.especificaciones}>
                        <Typography variant="body2">Especificaciones</Typography>
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        onClick={() => {
                            setSeccion({ especificaciones: "", ficha: "active" })
                        }}
                        className={"nav-link " + Seccion.ficha}>
                        <Typography variant="body2">Ficha Técnica</Typography>
                    </button>
                </li>
            </ul>
            <div className="py-3 px-3  border-left border-right border-bottom">
                {
                    Seccion.especificaciones === "active" && Seccion.ficha === "" ?

                        <div>
                            {props.DatosProducto.descripcion}
                        </div>


                        :
                        ""
                }
                {
                    Seccion.ficha === "active" && Seccion.especificaciones === "" ?
                        <div className="table-responsive">
                            <table className="table table-striped">                                
                                <tbody>
                                    <tr>
                                        <th scope="row">Peso</th>
                                        <td>{props.DatosProducto.var_peso} Kgs</td>                                        
                                    </tr>   
                                    <tr>
                                        <th scope="row">Marca</th>
                                        <td>{props.DatosProducto.marca}</td>                                        
                                    </tr>   
                                    {
                                        DatosProducto.productos_esp_index.map((especificacion,index)=>{
                                            if(DatosProducto[especificacion.variable]!==null){
                                                if(especificacion.tipo==="numero" || especificacion.tipo==="texto"){
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{especificacion.nombre}</th>
                                                            <td>{DatosProducto[especificacion.variable]}</td>
                                                        </tr>
                                                    )
                                                }else if(especificacion.tipo==="archivo"){

                                                    if(DatosProducto[especificacion.variable] && DatosProducto[especificacion.variable].length>0){
                                                        return(
                                                            <tr key={index}>
                                                                <th scope="row">{especificacion.nombre}</th>
                                                                <td className="d-flex align-items-center">
                                                                    <InsertDriveFileIcon/>
                                                                    <a className="ml-1" href={ DatosProducto.dir_pagina+"/assets/datos/ficha_tecnica/"+DatosProducto[especificacion.variable] } target="_blank">
                                                                        {especificacion.nombre}_{DatosProducto.SKU}
                                                                    </a>
                                                                    

                                                                </td>
                                                            </tr>
                                                        )
                                                    }else{
                                                        return(
                                                            <tr key={index}>
                                                                <th scope="row">{especificacion.nombre}</th>
                                                                <td className="d-flex align-items-center">

                                                                </td>
                                                            </tr>
                                                        )

                                                    }
                                                }else{
                                                    return false;
                                                }
                                            }else{
                                                return false;
                                            }
                                        })           
                                    }                                 
                                </tbody>
                            </table>
                        </div>
                        :
                        ""
                }
            </div>
        </div>

    );
}
