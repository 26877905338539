import React,{ useState, useEffect,useContext } from "react";
import { Button, Card, TextField, Typography } from "@material-ui/core";
import EstilosGlobales from "../../toremp/EstilosGlobales";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import axios from 'axios'



export default function ContenidoLogin() {
    const Rutas = [{ nombre: "Inicio", ruta: "/" }, { nombre: "Disponibilidad Despacho" }];
    const [LocalidadesRegion,setLocalidadesRegion] = useState([]);
    const [Cargado,setCargado] = useState(false);

    useEffect(()=>{
        const cargarLocalidadesHabilitadas=()=>{
            var datos = new FormData();
            datos.append("accion", "cargar_localidades_habilitadas");
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/despdn/localidades_habilitadas.php",
                method:"post",
                data:datos
            }).then((resp)=>{
                var res = resp.data;
                console.log(res);
                if(res[0]==="realizado") {
                    setLocalidadesRegion(res['localidades_region']);
                    setCargado(true);
                }
            })        
        }

        cargarLocalidadesHabilitadas();
    },[])

    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo className="mt-1" titulo="Disponibilidad Despacho"></Titulo>
                    {
                        Cargado && LocalidadesRegion.length>0? 
                        <div className="">
                            {
                                LocalidadesRegion.map((region,index)=>{
                                    return(
                                        <Region key={index} region={region}>
                                        </Region>
                                    );
                                })
                            }
                        </div>
                        :
                        <div className="">
                            Cargando
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function Region(){
    return(
        <div className="">
            ASD
        </div>
    )
}
