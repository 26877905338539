
const EstilosGlobales = {
    "colorPrimario":"#eb2125",
    "colorPrimarioContrast":"#e27f81",
    "colorSecundario":"#184790",    
    "colorTerciario":"#eb2125",    
    "colorCuarto":"#184790",    
    "Dark":"#1a1919",
    "especifico":{
        "barraRuta":{
            "fondo":"#343A40",
            "texto":"#ffffff"
        },
        "barraSuperior":{
            "fondo":"#1a1919",
            "texto":"#ffffff"
        },
        "menuDown":{
            "fondo":"#184790",
            "texto":"#ffffff"
        },
        "menuSuperfamilias":{
            "fondo":"#F8F9FA",
            "texto":"#343A40"
        },
        "footer":{
            "fondo":"#184790",
            "texto":"#F8F9FA"
        },
        "sidebarProductos":{
            "superfamilias":{
                "arrow":"#ffffff",
                "fondo":"#184790",
                "texto":"#ffffff"
            },
            "familias":{
                "arrow":"#343A40",
                "fondo":"#ffffff",
                "texto":"#343A40"
            },
            "subfamilias":{
                "arrow":"#eb2125",
                "fondo":"#ffffff",
                "texto":"#343A40"
            }
        }
        
    },
    "bootstrap":{
        "light":"#F8F9FA",
        "dark":"#343A40"
    }
}

export default EstilosGlobales;
