import {Typography} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CakeIcon from '@mui/icons-material/Cake';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InboxIcon from '@mui/icons-material/Inbox';
import LanguageIcon from '@mui/icons-material/Language';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Titulo from './TituloTorEmp'
import EstilosGlobales from '../EstilosGlobales'

export default function ProductoEspecificacionFicha(props){

    let {DatosProducto}=props;

    const IndexIconos={
        esp1:<CakeIcon/>,
        esp2:<GroupIcon/>,
        esp3:<GroupIcon/>,
        esp4:<AccessTimeIcon/>,
        esp5:<InboxIcon/>,
        esp8:<LanguageIcon/>,
        esp9:<DashboardIcon/>
    }

    return(
        <div className="mt-2 bg-white shadow-sm p-3">
            <Titulo size="small" className="px-1 px-md-2 mb-4">Ficha de Producto</Titulo>
            <div className="row">
            {
                DatosProducto.productos_esp_index.map((especificacion,index)=>{
                    if(DatosProducto[especificacion.variable]!==null){
                        if(especificacion.tipo==="numero" || especificacion.tipo==="texto"){
                            return(
                                <Especificacion key={index} className="col-6" icono={IndexIconos[especificacion.variable]} nombreVariable={especificacion.nombre} valorVariable={DatosProducto[especificacion.variable]}/>
                            )
                        }else if(especificacion.tipo==="archivo"){

                            if(DatosProducto[especificacion.variable] && DatosProducto[especificacion.variable].length>0){
                                return(
                                    
                                    <Especificacion key={index} className="col-6" icono={<InsertDriveFileIcon/>} nombreVariable={especificacion.nombre} 
                                        valorVariable={
                                            <a className="ml-1" href={ DatosProducto.dir_pagina+"/assets/datos/ficha_tecnica/"+DatosProducto[especificacion.variable] } target="_blank">
                                                {especificacion.nombre}_{DatosProducto.SKU}
                                            </a>
                                        }/>
                                )
                            }else{
                                return(
                                    <div key={index}>
                                        <th scope="row">{especificacion.nombre}</th>
                                        <td className="d-flex align-items-center">

                                        </td>
                                    </div>
                                )

                            }
                        }else{
                            return false;
                        }
                    }else{
                        return false;
                    }
                })
            }
            </div>
        </div>
    )
}

function Especificacion(props){

    let {className,icono,nombreVariable,valorVariable} = props;

    return(
        <div className={className+" mb-3"}>
            <div className="d-flex flex-row align-items-center">
                <div style={{color:EstilosGlobales.colorSecundario}}>
                    {icono}
                </div>
                <div className="d-flex flex-column ml-1">
                    <Typography variant="caption" className="font-weight-bold text-secondary" style={{lineHeight:"0.75rem"}}>
                        {nombreVariable}
                    </Typography>
                    <Typography variant="body2" className="font-weight-bold">
                        {valorVariable}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
