import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const PathScroller=(props)=>{
    const {children} = props;
    const {pathname} = useLocation();
    const excludedPathnames = ['/contacto/sucursales']
    useEffect(()=>{
        if(!(excludedPathnames.includes(pathname))){
            console.log({pathname})
            window.scroll(0,0);
            if(pathname==='/'){
                setTimeout(()=>{
                    window.scroll(0,0);
                },250)
            }
        }
    },[pathname]);//eslint-disable-line
    return(
        <>
            {children}
        </>
    );
};

export default PathScroller;