import axios from "axios";
import { useState, useEffect } from "react";
import Producto from "../Producto";
import Titulo from "../Titulo";
import LinearProgressMine from '../LinearProgressMine'
import {Typography} from "@material-ui/core";

export default function ProductosRelacionados(props) {
    let { Estado } = props.Estado;

    const [EstadoProdRel, setEstadoProdRel] = useState("inicio");
    const [ProductosRelacionados, setProductosRelacionados] = useState([]);
    const [SKUProdActual, setSKUProdActual] = useState("");

    useEffect(() => {
        if (
            EstadoProdRel === "inicio" &&
            Estado === "cargado" 
        ) {
            cargarProductosRelacionados();
        } else if (props.SKU !== SKUProdActual) {
            cargarProductosRelacionados();
        }
    });

    const cargarProductosRelacionados = () => {
        setEstadoProdRel("cargando");
        var datos = new FormData();
        //datos.append("subfamilia", props.SubfamiliaProducto);
        datos.append("SKU", props.SKU);
        setSKUProdActual(props.SKU);
        axios({
            url:
                process.env.PUBLIC_URL + "/assets/api/productos_rel_cargar.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {
                setProductosRelacionados(res["productos_relacionados"]);
                setEstadoProdRel("cargado");
            }
        });
    };

    return (
        <div className={props.className}>
            <Titulo className="mt-3 mb-2">Productos Relacionados</Titulo>
            {EstadoProdRel === "cargado"? (
                <div className="row mb-4 w-100">
                    {ProductosRelacionados.map((producto, index) => {
                        return (
                            <Producto
                                key={index}
                                className="col-lg-3 col-md-4 col-6 my-2 px-1 px-md-2 px-lg-3"
                                DatosProducto={producto}
                            ></Producto>
                        );
                    })}
                </div>
            ) : (
                <div className="w-100 py-5">
                    <div className="text-center">
                        <Typography variant="body1">
                            Cargando... 
                        </Typography>
                    </div>
                    <LinearProgressMine/>
                </div> 
            )}
        </div>
    );
}
