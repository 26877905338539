import {
    Button,
    Card,
    CircularProgress,
    TextField,
    Typography,
} from "@material-ui/core";
import EstilosGlobales from "../../toremp/EstilosGlobales";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {Link} from 'react-router-dom'
import LinearProgressMine from '../LinearProgressMine'
import {Alert} from '@material-ui/lab'

export default function ContenidoLogin() {
    let { id_recuperacion } = useParams();
    const Rutas = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Recuperar Contraseña" },
    ];
    const ClaseInputs = "col-md-6";
    const [DatosPassword, setDatosPassword] = useState({
        password: "",
        password_confirmar: "",
    });
    const [ErrorCampos, setErrorCampos] = useState({
        password: { error: false, texto: "" },
        password_confirmar: { error: false, texto: "" },
    });
    const [EstadoSubmited, setEstadoSubmited] = useState(false);
    const [PasswordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [PrintConsole, setPrintConsole] = useState();
    const [Estado, setEstado] = useState("inicio");
    const [PrePrint, setPrePrint] = useState(
        <div className="justify-content-center d-flex flex-row align-items-center">
            <CircularProgress />
            <Typography className="ml-2">Cargando</Typography>
        </div>
    );

    useEffect(() => {
        if (Estado === "inicio") {
            comprobarIdRecuperacion();
        }
    });

    const comprobarIdRecuperacion = () => {
        var datos = new FormData();
        datos.append("id_recuperacion", id_recuperacion);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/recuperar_pass_comp_id.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            var body_preprint = "";
            if (res[0] === "realizado") {
                setEstado("comprobado");
            } else {
                body_preprint = (
                    <div className="justify-content-center d-flex flex-column align-items-center">
                        <Typography variant="h5">Petición Inválida</Typography>
                        <Typography>
                            La petición o ID de operación es inválido.
                        </Typography>
                    </div>
                );
                setPrePrint(body_preprint);
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (EstadoSubmited) {
            //Si esta en submit
            return false;
        }

        var campos_requeridos = 0;
        var MsjeCampoReq = "Campo requerido";
        var ErrorCampoPre = {
            password: { error: false, texto: "" },
            password_confirmar: { error: false, texto: "" },
        };

        if (DatosPassword.password.length <= 0) {
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else if (
            DatosPassword.password.length > 0 &&
            DatosPassword.password.length < 8
        ) {
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto =
                "La password debe tener al menos 8 caracteres";
            campos_requeridos += 1;
        } else ErrorCampoPre.password.error = false;

        if (DatosPassword.password_confirmar.length <= 0) {
            ErrorCampoPre.password_confirmar.error = true;
            ErrorCampoPre.password_confirmar.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else if (
            DatosPassword.password_confirmar.length > 0 &&
            DatosPassword.password !== DatosPassword.password_confirmar
        ) {
            ErrorCampoPre.password_confirmar.error = true;
            ErrorCampoPre.password_confirmar.texto = "Passwords no coinciden.";
            campos_requeridos += 1;
        } else ErrorCampoPre.password_confirmar.error = false;

        setErrorCampos(ErrorCampoPre);
        if (campos_requeridos > 0) {
            return false;
        } else {
            setEstadoSubmited(true);
        }

        var datos = new FormData();
        datos.append("password", DatosPassword.password);
        datos.append("id_recuperacion", id_recuperacion);

        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/recuperar_pass_set.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {
                setPasswordResetSuccess(true);
                setPrintConsole(
                    <div className="d-flex flex-column justify-content-center align-items center py-3">
                        <Typography>
                            La contraseña de la cuenta fue <b>reestablecida satisfactoriamente</b>. Para ingresar diríjase a <Link to="/login">Login</Link>

                        </Typography>
                    </div>
                );
            }else if(res[0]==="error_envio"){
               setPrintConsole(
                    <Alert severity="error">Ocurrió un error inesperado con el envío. Intente nuevamente el proceso, si el error persiste contáctese con Soporte.</Alert>
               );
            }else if(res[0]==="no_existe_cuenta"){

               setPrintConsole(
                    <Alert severity="error">La solicitud es inválida. Realice nuevamente el proceso, si presenta inconvenientes, contáctese con soporte.</Alert>
               );
            }else{
                
               setPrintConsole(
                    <Alert severity="error">Ha ocurrido un error inesperado. Recargue e intente nuevamente la operación. Si el error persiste, contáctese con Soporte. </Alert>
               );
            }
        });
    };

    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo titulo="Cuenta"></Titulo>
                    <div className="row py-4 justify-content-center">
                        <div className="col-md-8 col-sm-10 col-12">
                            <Card className="py-4 px-2 text-center">
                                <Titulo className="px-5 mb-4">
                                    Reestablecer Contraseña
                                </Titulo>
                                {!PasswordResetSuccess ? (
                                    <>
                                        {Estado === "comprobado" ? (
                                            <form
                                                onSubmit={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            >
                                                <div className="row justify-content-center mx-2">
                                                    <div
                                                        className={ClaseInputs}
                                                    >
                                                        <TextField
                                                            label="Nueva Password"
                                                            type="password"
                                                            name="password"
                                                            fullWidth
                                                            onChange={(e) => {
                                                                setDatosPassword(
                                                                    {
                                                                        ...DatosPassword,
                                                                        password:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                );
                                                            }}
                                                            error={
                                                                ErrorCampos
                                                                    .password
                                                                    .error
                                                            }
                                                            helperText={
                                                                ErrorCampos
                                                                    .password
                                                                    .texto
                                                            }
                                                        ></TextField>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center mx-2 mt-4">
                                                    <div
                                                        className={ClaseInputs}
                                                    >
                                                        <TextField
                                                            label="Reingrese Password"
                                                            type="password"
                                                            name="password_confirmar"
                                                            fullWidth
                                                            onChange={(e) => {
                                                                setDatosPassword(
                                                                    {
                                                                        ...DatosPassword,
                                                                        password_confirmar:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                );
                                                            }}
                                                            error={
                                                                ErrorCampos
                                                                    .password_confirmar
                                                                    .error
                                                            }
                                                            helperText={
                                                                ErrorCampos
                                                                    .password_confirmar
                                                                    .texto
                                                            }
                                                        ></TextField>
                                                    </div>
                                                </div>
                                                {EstadoSubmited?
                                                    <div>
                                                        <LinearProgressMine  className="my-2"/>
                                                        <Typography>
                                                            Cargando...
                                                        </Typography>
                                                    </div>:""
                                                }
                                                <div className="row justify-content-center mx-2 mt-3">
                                                    {PrintConsole}
                                                </div>
                                                <div className="row justify-content-center mx-2 mt-3">
                                                    <Button
                                                        className="text-white"
                                                        type="submit"
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor:
                                                                EstilosGlobales.colorPrimario,
                                                        }}
                                                    >
                                                        Cambiar Contraseña
                                                    </Button>
                                                </div>
                                            </form>
                                        ) : (
                                            <div className="">{PrePrint}</div>
                                        )}
                                    </>
                                ) : (
                                    <div>{PrintConsole}</div>
                                )}
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
