import {useContext} from 'react'
import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import { createMuiTheme, ThemeProvider, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import useWindowSize from '../context/useWindowSize'
import MetaData from '../context/MetaData'
import EstilosGlobales from '../toremp/EstilosGlobales'
import DatosConfigContext from '../context/config'

const theme=createMuiTheme();

theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };


export default function NoEncontrado(){
    const size=useWindowSize();
    const {DatosConfig} = useContext(DatosConfigContext);
    return(
        <>
            <MetaData titulo="Página no encontrada"/>
            <Header></Header>
            <section className="bg-light" style={{height:(size.height)*0.8}}>
                <div className="container py-4">
                    <div className="border bg-white rounded shadow-sm p-3 p-lg-5">
                        <div className="row">
                            <div className="col">
                                
                                <Typography variant="h2" style={{color:EstilosGlobales.colorPrimario}}>Error 404</Typography>
                                <ThemeProvider theme={theme}>
                                    <Typography variant="h4" className="text-dark my-3">No se ha encontrado la sección que estabas buscando. </Typography>
                                </ThemeProvider>
                                <Typography variant="body1" className="text-dark my-3">Sigue navegando en <Link to="/">{DatosConfig.Empresa.nombre}.</Link> </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
            <Footer></Footer>
        </>
    );
    
}
