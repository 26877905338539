import { useState, useEffect } from "react";
import {
    CircularProgress,
    Typography,
    Card,
    TextField,
    Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { validate, /*clean,*/ format } from "rut.js";
import LinearProgressMine from "../../LinearProgressMine";
import EstilosGlobales from "../../../toremp/EstilosGlobales.jsx";

export default function MiCuenta() {
    const [DatosCuenta, setDatosCuenta] = useState({
        nombre: "",
        RUT: "",
        razon_social: "",
        RUT_razon_social: "",
        telefono: "",
        ciudad: "",
        region: "",
        direccion: "",
        email: "",
    });
    const [DatosCuentaOriginales, setDatosCuentaOriginales] = useState({
        nombre: "",
        RUT: "",
        razon_social: "",
        RUT_razon_social: "",
        telefono: "",
        ciudad: "",
        region: "",
        direccion: "",
        email: "",
    });

    const [ErrorCampos, setErrorCampos] = useState({
        razon_social: { error: false, texto: "" },
        RUT_razon_social: { error: false, texto: "" },
        telefono: { error: false, texto: "" },
        ciudad: { error: false, texto: "" },
        direccion: { error: false, texto: "" },
    });

    const [DatosCuentaPass, setDatosCuentaPass] = useState({
        password: "",
        password_confirm: "",
    });

    const [ErrorCamposPass, setErrorCamposPass] = useState({
        password: { error: false, texto: "" },
        password_confirm: { error: false, texto: "" },
    });

    useEffect(() => {
        cargarDatosCuenta();
    }, []);

    const [Cargado, setCargado] = useState(false);
    const [PrintConsole1, setPrintConsole1] = useState();
    const [PrintConsole2, setPrintConsole2] = useState();
    const [EstadoSubmited, setEstadoSubmited] = useState(false);

    const cargarDatosCuenta = () => {
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/micuenta_cargar_datos.php",
        }).then((resp) => {
            var res = resp.data;
            if (res[0] === "realizado") {
                setDatosCuenta(res["datos_cuenta"]);
                setDatosCuentaOriginales(res["datos_cuenta"]);
                setCargado(true);
            }
        });
    };

    const handleActualizarDatos = () => {
        if (EstadoSubmited) {
            //Si esta en submit
            return false;
        }

        var Modificacion = false;
        if (DatosCuenta.razon_social !== DatosCuentaOriginales.razon_social)
            Modificacion = true;
        if (
            DatosCuenta.RUT_razon_social !==
            DatosCuentaOriginales.RUT_razon_social
        )
            Modificacion = true;
        if (DatosCuenta.ciudad !== DatosCuentaOriginales.ciudad)
            Modificacion = true;
        if (DatosCuenta.direccion !== DatosCuentaOriginales.direccion)
            Modificacion = true;
        if (DatosCuenta.telefono !== DatosCuentaOriginales.telefono)
            Modificacion = true;

        if (!Modificacion) {
            setPrintConsole1(
                <Alert severity="warning">
                    No se ha realizado ningún cambio en los datos.
                </Alert>
            );
            return false;
        } else {
            setPrintConsole1("");
        }

        var campos_requeridos = 0;
        var MsjeCampoReq = "Campo requerido";
        var ErrorCampoPre = {
            razon_social: { error: false, texto: "" },
            RUT_razon_social: { error: false, texto: "" },
            telefono: { error: false, texto: "" },
            ciudad: { error: false, texto: "" },
            direccion: { error: false, texto: "" },
        };

        if (DatosCuenta.ciudad.length <= 0) {
            ErrorCampoPre.ciudad.error = true;
            ErrorCampoPre.ciudad.texto = "Debe elegir una ciudad";
            campos_requeridos += 1;
        } else ErrorCampoPre.ciudad.error = false;

        if (DatosCuenta.direccion.length <= 0) {
            ErrorCampoPre.direccion.error = true;
            ErrorCampoPre.direccion.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.direccion.error = false;

        if (DatosCuenta.telefono.length <= 0) {
            ErrorCampoPre.telefono.error = true;
            ErrorCampoPre.telefono.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.telefono.error = false;

        setErrorCampos(ErrorCampoPre);
        if (campos_requeridos > 0) {
            return false;
        } else {
            setEstadoSubmited(true);
            setPrintConsole1(
                <div className="d-flex flex-row align-items-center">
                    <CircularProgress />
                    <Typography className="ml-2">Cargando..</Typography>
                </div>
            );
        }

        var datos = new FormData();
        datos.append("razon_social", DatosCuenta.razon_social);
        datos.append("RUT_razon_social", DatosCuenta.RUT_razon_social);
        datos.append("telefono", DatosCuenta.telefono);
        datos.append("ciudad", DatosCuenta.ciudad);
        datos.append("direccion", DatosCuenta.direccion);

        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/micuenta_actualizar_datos.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {
                //Actualizar Datos Originales una vez hecho el update
                setEstadoSubmited(false);
                setPrintConsole1(
                    <Alert severity="success">
                        Los datos fueron actualizados
                    </Alert>
                );
                setDatosCuentaOriginales(DatosCuenta);
            }else{
                setPrintConsole1(
                    <Alert severity="error">
                        Ocurrió un error inesperado. Recargue e intente nuevamente. Si el error persiste, contacte con soporte.
                    </Alert>
                );
            }
        });
    };

    const handleActualizarPassword = (e) => {
        e.preventDefault();
        if(EstadoSubmited){
            return false;
        }

        var campos_requeridos = 0;
        var MsjeCampoReq = "Campo requerido";
        var ErrorCampoPre = {
            password: { error: false, texto: "" },
            password_confirm: { error: false, texto: "" }
        };

        if (DatosCuentaPass.password.length <= 0) {
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else if(DatosCuentaPass.password.length>0 && DatosCuentaPass.password.length<8){
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto = "Password debe tener al menos 8 caracteres";
            campos_requeridos += 1;
        }
        else ErrorCampoPre.password.error = false;

        if (DatosCuentaPass.password_confirm.length <= 0) {
            ErrorCampoPre.password_confirm.error = true;
            ErrorCampoPre.password_confirm.texto = MsjeCampoReq;
            campos_requeridos += 1;
        }else if(DatosCuentaPass.password_confirm!==DatosCuentaPass.password){
            ErrorCampoPre.password_confirm.error = true;
            ErrorCampoPre.password_confirm.texto = "Password no coinciden";
            campos_requeridos += 1;
        }
        else ErrorCampoPre.password_confirm.error = false;

        setErrorCamposPass(ErrorCampoPre);
        if (campos_requeridos > 0) {
            return false;
        } else {
            setEstadoSubmited(true);
            setPrintConsole2(
                <div className="d-flex flex-row align-items-center">
                    <CircularProgress />
                    <Typography className="ml-2">Cargando..</Typography>
                </div>
            );
        }

        var datos = new FormData();
        datos.append("password",DatosCuentaPass.password);
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/micuenta_actualizar_password.php",
            method:"post" ,
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            if(res[0]==="realizado") {
                setEstadoSubmited(false);
                setPrintConsole2(
                    <Alert severity="success">La contraseá fue actualizada.</Alert>
                );
                setDatosCuentaPass({
                    password:"",
                    password_confirm:""
                })
            }else{
                setPrintConsole2(
                    <Alert severity="error">Ocurrió un error inesperado. Recargue e intente nuevamente. Si el error persiste, contacte a soporte.</Alert>
                );
            }
        })


    };

    const ClaseInput = "col-md-6 my-2";

    if (Cargado) {
        return (
            <Card className="px-3 py-3">
                <div className="row justify-content-start">
                    <div className={ClaseInput}>
                        <TextField
                            label="Nombre"
                            value={DatosCuenta.nombre}
                            disabled
                            fullWidth
                        ></TextField>
                    </div>
                    <div className={ClaseInput}>
                        <TextField
                            label="RUT"
                            value={DatosCuenta.RUT}
                            disabled
                            fullWidth
                        ></TextField>
                    </div>
                    <div className={ClaseInput}>
                        <TextField
                            label="Email"
                            value={DatosCuenta.email}
                            disabled
                            fullWidth
                        ></TextField>
                    </div>
                </div>
                <hr />
                <div className="row justify-content-start">
                    <div className={ClaseInput}>
                        <TextField
                            type="text"
                            label="Razon Social"
                            fullWidth
                            onChange={(e) => {
                                setDatosCuenta({
                                    ...DatosCuenta,
                                    razon_social: e.target.value,
                                });
                            }}
                            error={ErrorCampos.razon_social.error}
                            helperText={ErrorCampos.razon_social.texto}
                            value={DatosCuenta.razon_social}
                        ></TextField>
                    </div>
                    <div className={ClaseInput}>
                        <TextField
                            type="text"
                            label="RUT Razon Social"
                            fullWidth
                            onChange={(e) => {
                                setDatosCuenta({
                                    ...DatosCuenta,
                                    RUT_razon_social: e.target.value,
                                });
                            }}
                            onBlur={(e) => {
                                var RUT_final = "";
                                RUT_final = e.target.value;
                                format(RUT_final);
                                if (validate(RUT_final)) {
                                    RUT_final = format(RUT_final);
                                    setDatosCuenta({
                                        ...DatosCuenta,
                                        RUT_razon_social: RUT_final,
                                    });
                                    setErrorCampos({
                                        ...ErrorCampos,
                                        RUT_razon_social: {
                                            error: false,
                                            texto: "",
                                        },
                                    });
                                } else {
                                    setDatosCuenta({
                                        ...DatosCuenta,
                                        RUT_razon_social: "",
                                    });
                                    setErrorCampos({
                                        ...ErrorCampos,
                                        RUT_razon_social: {
                                            error: true,
                                            texto: "Rut inválido",
                                        },
                                    });
                                }
                            }}
                            value={DatosCuenta.RUT_razon_social}
                            error={ErrorCampos.RUT_razon_social.error}
                            helperText={ErrorCampos.RUT_razon_social.texto}
                        ></TextField>
                    </div>
                    <SeleccionLocalidad
                        DatosCuenta={{ DatosCuenta, setDatosCuenta }}
                        ErrorCampos={{ ErrorCampos, setErrorCampos }}
                        ClaseInput={{ ClaseInput }}
                    />
                    <div className={ClaseInput}>
                        <TextField
                            type="text"
                            label="Dirección"
                            fullWidth
                            onChange={(e) => {
                                setDatosCuenta({
                                    ...DatosCuenta,
                                    direccion: e.target.value,
                                });
                            }}
                            error={ErrorCampos.direccion.error}
                            helperText={ErrorCampos.direccion.texto}
                            value={DatosCuenta.direccion}
                        ></TextField>
                    </div>
                    <div className={ClaseInput}>
                        <TextField
                            type="text"
                            label="Teléfono"
                            fullWidth
                            onChange={(e) => {
                                setDatosCuenta({
                                    ...DatosCuenta,
                                    telefono: e.target.value,
                                });
                            }}
                            error={ErrorCampos.telefono.error}
                            helperText={ErrorCampos.telefono.texto}
                            value={DatosCuenta.telefono}
                        ></TextField>
                    </div>
                </div>
                <div className="row justify-content-center my-1">
                    {PrintConsole1}
                </div>
                <div
                    onClick={handleActualizarDatos}
                    className="row justify-content-center pb-3"
                >
                    <Button
                        className="text-white"
                        variant="contained"
                        style={{
                            backgroundColor: EstilosGlobales.colorPrimario,
                        }}
                    >
                        Actualizar Datos
                    </Button>
                </div>
                <hr />
                <form onSubmit={(e)=>{handleActualizarPassword(e);}}>
                    <div className="row">
                        <div className={ClaseInput}>
                            <TextField
                                type="password"
                                label="Password"
                                fullWidth
                                onChange={(e) => {
                                    setDatosCuentaPass({
                                        ...DatosCuentaPass,
                                        password: e.target.value,
                                    });
                                }}
                                error={ErrorCamposPass.password.error}
                                helperText={ErrorCamposPass.password.texto}
                                value={DatosCuentaPass.password}
                            ></TextField>
                        </div>
                        <div className={ClaseInput}>
                            <TextField
                                type="password"
                                label="Password"
                                fullWidth
                                onChange={(e) => {
                                    setDatosCuentaPass({
                                        ...DatosCuentaPass,
                                        password_confirm: e.target.value,
                                    });
                                }}
                                error={ErrorCamposPass.password_confirm.error}
                                helperText={
                                    ErrorCamposPass.password_confirm.texto
                                }
                                value={DatosCuentaPass.password_confirm}
                            ></TextField>
                        </div>
                    </div>
                    <div className="row justify-content-center my-1">
                        {PrintConsole2}
                    </div>
                    <div className="row justify-content-center pb-3">
                        <Button
                            type="submit"
                            className="text-white"
                            variant="contained"
                            style={{
                                backgroundColor:
                                    EstilosGlobales.colorSecundario,
                            }}
                        >
                            Actualizar Password
                        </Button>
                    </div>
                </form>
            </Card>
        );
    } else {
        return (
            <div>
                <LinearProgressMine />
                <div className="d-flex flex-row justify-content-center mt-2">
                    Cargando ...
                </div>
            </div>
        );
    }
}

function SeleccionLocalidad(props) {
    let { DatosCuenta, setDatosCuenta } = props.DatosCuenta;
    let { ErrorCampos } = props.ErrorCampos;
    let { ClaseInput } = props.ClaseInput;
    const [Regiones, setRegiones] = useState([]);
    const [Region, setRegion] = useState("");
    const [Ciudades, setCiudades] = useState([]);
    const [SelCiudad, setSelCiudad] = useState(true);
    const [Cargado, setCargado] = useState(false);

    useEffect(() => {
        if (!Cargado) cargarRegiones();
    });

    const cargarRegiones = () => {
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/registrar_regiones_cargar.php",
        }).then((resp) => {
            var res = resp.data;
            setRegiones(res);
            //Cargar Seleccionada
            setRegion(DatosCuenta.region);
            cargarCiudadSel();
        });
    };

    const handleCambioRegion = (e) => {
        var datos = new FormData();
        datos.append("region", e.target.value);

        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/registrar_ciudades_cargar.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            setCiudades(res);
            setSelCiudad(false);
        });
    };
    const cargarCiudadSel = () => {
        var datos = new FormData();
        datos.append("region", DatosCuenta.region);

        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/registrar_ciudades_cargar.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            setCiudades(res);
            setSelCiudad(false);
            setCargado(true);
        });
    };

    if (Cargado) {
        return (
            <>
                <div className={ClaseInput}>
                    <select
                        value={Region}
                        onChange={(e) => {
                            handleCambioRegion(e);
                            setRegion(e.target.value);
                            setDatosCuenta({ ...DatosCuenta, ciudad: "" });
                        }}
                        className="form-control my-2"
                    >
                        <option value="">Seleccione Región</option>
                        {Regiones.map((region, index) => {
                            return (
                                <option key={index} value={region.region}>
                                    {region.nombre}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className={ClaseInput}>
                    <select
                        value={DatosCuenta.ciudad}
                        onChange={(e) => {
                            setDatosCuenta({
                                ...DatosCuenta,
                                ciudad: e.target.value,
                            });
                        }}
                        className="form-control my-2"
                        disabled={SelCiudad}
                    >
                        <option value="">Seleccione Ciudad</option>
                        {Ciudades.map((ciudad, index) => {
                            return (
                                <option key={index} value={ciudad.nombre}>
                                    {ciudad.nombre}
                                </option>
                            );
                        })}
                    </select>
                    {ErrorCampos.ciudad.error ? (
                        <Typography
                            className="font-weight-bold text-danger"
                            variant="body2"
                        >
                            * {ErrorCampos.ciudad.texto} *
                        </Typography>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    } else {
        return (
            <div className="d-flex flex-row jutify-content-center align-items-center">
                <CircularProgress></CircularProgress>
                <Typography className="ml-2 font-weight-bold">
                    Cargando
                </Typography>
            </div>
        );
    }
}
