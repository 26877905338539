import {Typography} from '@material-ui/core'
import EstilosGlobales from '../toremp/EstilosGlobales'
import Swal from '../context/SwalAlert.jsx'
import InfoIcon from '@material-ui/icons/Info';
export default function AdminLogeadoPrint(){

    const DesplegarInfo=()=>{
        const HtmlBody = <div className="">
            Al estar logeado como administrador, podrás navegar por la página apesar de que esta se encuentre en mantención.
        </div>;

        Swal.fire({
            title:"Modo Administrador",
            html:HtmlBody,
            icon:"info",
            showCloseButton:true,
            showConfirmButton:false
             
        }) 
    }
    return(
        <div className="" style={{backgroundColor:EstilosGlobales.colorPrimario}}>
            <div className="container py-2" >
                <div className="row justify-content-center">
                    <Typography className="text-white" variant="body2">
                        <span className="font-weight-bold">
                            Esta logeado como Administrador 
                        </span>
                        <span onClick={DesplegarInfo} className="btn btn-warning btn-sm ml-2">
                            <InfoIcon/> 
                        </span>
                    </Typography>
                </div>
            </div>
        </div>
    );
}
