import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import MetaData from '../context/MetaData'
import ContenidoDespDisponible from '../components/despdn/ContenidoDespDisponible'

export default function Inicio(){
    return(
        <div>         
            <MetaData titulo="Disponibilidad Despacho"/>
            <Header>            
            </Header>
            <ContenidoDespDisponible/>
            <Footer></Footer>
        </div>
    );
}
