import Ruta from '../Ruta'
import Titulo from '../Titulo'
import {useState,useEffect,useContext} from 'react'
import axios from 'axios';
import { TextField, Typography,Button } from '@material-ui/core';
import number_format from '../../context/numberFormat'
import Swal from '../../context/SwalAlert'
import DeleteIcon from '@material-ui/icons/Delete';
import Carro from '../../context/Carro'
import LinearProgressMine from '../LinearProgressMine'
import { Link } from 'react-router-dom';
import EstilosGlobales from '../../toremp/EstilosGlobales.jsx'
//META
import MetaData from '../../context/MetaData'


export default function ContenidoCarro(){

    const {DatosCarro} = useContext(Carro);

    const [Estado,setEstado] = useState("inicio");
    const [ProductosCarro,setProductosCarro] = useState([]);
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarProductosCarro();
        }
    })
    
    const cargarProductosCarro=()=>{
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_ver.php"            
        }).then((resp)=>{
            var res = resp.data;
             
            //console.log(res); 
            if(res[0]==="realizado"){
                if(res['productos_carro'])setProductosCarro(res['productos_carro']);
                else setProductosCarro([]);
                setEstado("cargado");
            }
            if(res['reporte'] && res['reporte'].length>0){
                if(res['reporte'].includes("borrado_stock")){
                    Swal.fire({
                        title:"Productos Borrados",
                        icon:"warning",
                        html:"Algunos productos <b>quedaron sin stock</b> mientras navegabas por el E-Commerce."	
                    })
                }
                if(res['reporte'].includes("borrado_noexiste")){
                    Swal.fire({
                        title:"Productos Borrados",
                        icon:"warning",
                        html:"Algunos productos <b>quedaron sin stock</b> mientras navegabas por el E-Commerce."	
                    })
                }
            }

        })
    }

    return(
        <div>
            <MetaData titulo="Carro de Compras"/>
            <Ruta ruta={[
                {nombre:"Inicio",ruta:"/"},
                {nombre:"Carro de Compras"}
            ]}>
            </Ruta>
            <div className="bg-light" style={{minHeight:window.innerHeight*0.7}}>
                {
                    Estado==="cargando" || Estado==="inicio"?
                    <LinearProgressMine></LinearProgressMine>:""
                }
                <div className="container py-4">
                    <Titulo titulo="Carro de Compras"></Titulo>                    
                    <div className="row">
                        <div className="col-md-8 bg-white shadow-sm border py-4 mt-3">
                            {Estado==="cargado"?
                            <div>                                
                                {
                                    ProductosCarro.map((producto,index)=>{
                                        return(
                                            <ProductoCarro EstadoPadre={{Estado,setEstado}} className="my-4" key={index} DatosProducto={producto}></ProductoCarro>
                                        );
                                        
                                    })
                                }
                            </div>
                            :""}
                            {
                                ProductosCarro.length>0?
                                "":
                                <Typography variant="h6" className="text-center text-dark">El carro se encuentra <b>vacío</b>.<br></br><Link to="/" className="text-decoration-none">Volver a Productos</Link></Typography>
                            }
                        </div>  
                        <div className="col-md-4 px-2 mt-3">
                            <div className="bg-white shadow-sm border py-4 px-5">
                                <Titulo titulo="Resumen"></Titulo>
                                <Typography variant="body2" className="text-secondary pt-2 mt-3 font-weight-bold">
                                    SUBTOTAL ({DatosCarro.cantidad_productos})
                                </Typography>
                                <Typography style={{color:EstilosGlobales.colorSecundario}} variant="h6" className="font-weight-bold text-right">
                                    ${number_format(DatosCarro.total,0,",",".")}
                                </Typography>
                                <Typography variant="body2" className="text-secondary pt-2 mt-3 font-weight-bold">
                                    ENVÍO ({"0 Kgs."})
                                </Typography>
                                <Typography variant="body1" className="text-dark font-weight-bold text-right">
                                    $0
                                </Typography>
                                <hr></hr>
                                
                                <Typography variant="h6" className="text-dark pt-2 font-weight-bold">
                                    TOTAL
                                </Typography>
                                <Typography style={{color:EstilosGlobales.colorPrimario}} variant="h5" className="font-weight-bold text-right">
                                    ${number_format(parseInt(DatosCarro.total),0,",",".")}
                                </Typography>
                                <div className="text-center mt-4">
                                    {ProductosCarro.length>0?
                                        <Link className="text-decoration-none" to="/carroet2">
                                            <Button style={{backgroundColor:EstilosGlobales.colorPrimario}} className="font-weight-bold text-white py-3" variant="contained" size="large">
                                                Procesar Pago
                                            </Button>
                                        </Link>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            
        </div>
    );
}

function ProductoCarro(props){
    

    const [Cantidad,setCantidad] = useState(props.DatosProducto.cantidad);

    const {ActualizarCarro} = useContext(Carro);
    
    const Columnas = {
        imagen:"col-3 col-md-2",
        datos:"col-6 col-md-4",
        cantidad:"col-5 col-xs-3 col-md-3",
        precio:"col-5 col-md-12 col-lg-2 mb-3 mb-md-0 pt-4 pt-md-0 text-right"
    }

    const handleSubmitCantidad=(e)=>{
        e.preventDefault();
        if(props.DatosProducto.cantidad===Cantidad){
            return false;
        }
        var cantidad = 1;
        if(Cantidad>1){
            cantidad=Cantidad;
        }
        /* console.log("Submit Cantidad: "+Cantidad+" para producto SKU:"+props.DatosProducto.SKU); */

        var datos=new FormData();
        datos.append("SKU",props.DatosProducto.SKU);
        datos.append("cantidad",cantidad);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_editar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            /* console.log(res); */
            var htmlbody = "";
            if(res[0]==="realizado"){
                htmlbody=
                <div>
                    La cantidad del producto <b style={{color:EstilosGlobales.colorSecundario}}>SKU:</b><b> {props.DatosProducto.SKU}</b> fue modificada satisfactoriamente
                </div>;
                Swal.fire({
                    title:"Cantidad Modificada",
                    html:htmlbody,
                    icon:"success",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
                props.EstadoPadre.setEstado("inicio");
                ActualizarCarro();
            }else if(res[0]==="no_encontrado"){
                htmlbody=
                <div>
                    El producto no fue encontrado en el carro de compras. Actualiza la página para continuar. Si persiste el problema contacta con Soporte.
                </div>;
                Swal.fire({
                    title:"Producto no Encontrado",
                    html:htmlbody,
                    icon:"error",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
            }else if(res[0]==="error_stock"){
                htmlbody=
                <div>
                    La nueva cantidad <b>supera el stock disponible</b>.
                </div>;
                Swal.fire({
                    title:"Stock producto",
                    html:htmlbody,
                    icon:"warning",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
                setCantidad(props.DatosProducto.cantidad);            
            }else if(res[0]==="error_limitecompra"){
                htmlbody=
                <div>
                    La nueva cantidad <b>supera el limite de compra máximo de este producto</b>.
                </div>;
                Swal.fire({
                    title:"Editar Cantidad",
                    html:htmlbody,
                    icon:"warning",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
                setCantidad(props.DatosProducto.cantidad);
            }
        })
    }

    const handleDelete=()=>{
        /* console.log("Borrar Producto "+props.DatosProducto.SKU); */
        var datos = new FormData();

        datos.append("SKU",props.DatosProducto.SKU);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_borrar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var htmlbody;
            var res = resp.data;
            if(res[0]==="realizado"){
                htmlbody=
                <div>
                    El producto <b style={{color:EstilosGlobales.colorSecundario}}>SKU:</b><b> {props.DatosProducto.SKU}</b> fue <b>eliminado satisfactoriamente.</b>
                </div>;
                Swal.fire({
                    title:"Producto Borrado",
                    html:htmlbody,
                    icon:"success",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
                ActualizarCarro();
                props.EstadoPadre.setEstado("inicio");
            }
        })
    }

    return(
        <div className={props.className}>
            <div className="row px-1 px-md-4 align-items-center">            
                <div className={""+Columnas.imagen}>
                    <div className="shadow-sm border rounded" style={{maxWidth:"100px"}}>
                        <img src={process.env.PUBLIC_URL+"/assets/imagenes/productos/"+props.DatosProducto.img_01} className="img-fluid" alt="prod"></img>
                    </div>
                </div>
                <div className={"ml-3 d-flex flex-column "+Columnas.datos}>
                    <Typography variant="caption">
                        <b className="mr-1" style={{color:EstilosGlobales.colorPrimario}}>SKU:</b>{props.DatosProducto.SKU}
                    </Typography>
                    <Typography style={{fontSize:"18px"}} variant="h6">
                        {props.DatosProducto.nombre}
                    </Typography>  
                    <div>
                        {props.DatosProducto.oferta>0?
                            <div className="d-flex flex-row align-items-end">
                                <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorSecundario}} variant="body1">
                                    ${number_format(props.DatosProducto.precio_oferta,0,",",".")}
                                </Typography>
                                <Typography className="text-secondary ml-2" variant="body2" style={{textDecoration:"line-through"}}>
                                ${number_format(props.DatosProducto.precio,0,",",".")}
                                </Typography>
                            </div>
                            :
                            <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorSecundario}} variant="body1">
                                ${number_format(props.DatosProducto.precio,0,",",".")}
                            </Typography>                            
                        }
                    </div>
                    {
                        props.DatosProducto.retirable>0?
                        <Typography className="text-success font-weight-bold" variant="caption">Retiro en Tienda: Disponible</Typography>
                        :
                        <Typography className="text-danger font-weight-bold" variant="caption">Retiro en Tienda: No Disponible</Typography>
                    }
                    {
                        props.DatosProducto.despachable>0?
                        <Typography className="text-success font-weight-bold" variant="caption">Despacho: Disponible</Typography>
                        :
                        <Typography className="text-danger font-weight-bold" variant="caption">Despacho: No Disponible</Typography>
                    }
                </div>
                <div className={"ml-4 mt-1 "+Columnas.cantidad}>  
                    <form onSubmit={(e)=>{handleSubmitCantidad(e)}}>
                        <TextField
                            id={props.DatosProducto.SKU}
                            label="Cant."                        
                            type="number"
                            variant="outlined"
                            style={{width:"6em"}}
                            
                            size="small"
                            onChange={(e)=>{
                                if(e.target.value<0){
                                    setCantidad(1);
                                }else{
                                    setCantidad(e.target.value);
                                }
                            }}
                            value={Cantidad}                                                                        
                        />
                    </form>                   
                </div>
                <div className={"position-relative d-flex flex-row justify-content-end px-0 "+Columnas.precio}>
                    <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorPrimario}} variant="h6">
                        ${number_format(props.DatosProducto.subtotal,0,",",".")}
                    </Typography>    
                    
                    <button onClick={handleDelete} variant="contained" className="btn btn-danger ml-4 px-2 py-1 shadow-sm" size="small">
                        <DeleteIcon className="text-white"></DeleteIcon>
                    </button>
                </div>
            </div>
        </div>
    );
}
