import {useState,useEffect} from 'react'
import Carousel from '../Carousel'
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgressMine from '../LinearProgressMine'


export default function BannerInicio(){
    const [Cargado,setCargado] = useState(false);
    const [Banners,setBanners] = useState([
        
    ]);

    

    useEffect(()=>{
        if(!Cargado){
            cargarBanners();
        }
    })

    const cargarBanners=()=>{
        /* setCargado(true);    */
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/banners_cargar.php"            
        }).then((resp)=>{
            var res = resp.data;            
            setCargado(true);
            setBanners(res);
        })
    }

    return(
        <>
            {
                Cargado?
                <Carousel banners={Banners}></Carousel>
                :
                <div className="bg-light rounded shadow-sm" style={{height:"300px"}}>                            
                    <div className="">
                        <LinearProgressMine/>
                    </div> 
                    <Skeleton variant="rect" className="h-100"></Skeleton>
                                    
                </div>
                
            }
        </>
    );
}
