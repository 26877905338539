import React, {useContext} from 'react'
import DatosConfigContext from '../../../context/config.jsx'
import {Typography} from '@material-ui/core'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EstilosGlobales from '../../../toremp/EstilosGlobales.jsx'
import {Link} from 'react-router-dom'
import { Container, Divider, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import PhoneIcon from "@material-ui/icons/Phone";

export default function MenuSucursales(){

    const {DatosConfig} = useContext(DatosConfigContext);

    return(
        <Grid sx={{paddingBottom:'5px'}}>
            <Container>
                <Grid container sx={{justifyContent:'center'}}>
                    {
                        DatosConfig.Empresa.sucursales.map((sucursal,index)=>{
                            /*if(DatosConfig.Empresa.sucursales[index+1]){
                            }*/
                            return(
                                <React.Fragment key={index}>
                                    <Sucursal nombre={sucursal.nombre} telefono={sucursal.telefono}/>
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>
            </Container>
        </Grid>
    );
}

const SucursalContainer=({isMobile,children,telefono})=>{
    if(isMobile){
        return(
        <a className="d-flex flex-row align-items-center text-decoration-none" style={{color:'#fff',height:'auto',width:'100%',justifyContent:'center!important'}} href={"tel:"+telefono}>
            {children}
        </a>
        );
        
    }else{
        return(
            <Link to="/contacto/sucursales" className="d-flex flex-row align-items-center text-decoration-none" style={{width:'100%',justifyContent:'center'}}>
                {children}
            </Link>
        );
    }
}

function Sucursal(props){
    let {nombre,telefono} = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
    <Grid xs={4} sm={4} md={2} lg={2} sx={{marginTop:{xs:'5px',md:'0px'}}}>
        <Paper sx={{backgroundColor:EstilosGlobales.colorSecundario,marginInline:'5px',borderRadius:'8px',paddingInline:'15px',display:'flex',flexDirection:'column',alignItems:'center',paddingTop:'3px',paddingBottom:'3px',height:'100%',justifyContent:'center',overflow:'hidden'}}>
            <SucursalContainer isMobile={isMobile} telefono={telefono[0]}>
                {
                    isMobile?
                    <PhoneIcon style={{fill:EstilosGlobales.colorPrimario,width:'20px',marginRight:'5px'}}></PhoneIcon>:
                    <LocationOnIcon style={{color:EstilosGlobales.colorPrimario}} />
                }
                <Typography className="py-0 my-0 font-weight-bold" variant="body2" style={{color:'#fff',maxWidth:'90px'}}>
                    {nombre}
                </Typography>
            </SucursalContainer>
            <Divider sx={{width:'100%',borderColor:'#fff',opacity:'0.15',paddingInline:'15px',display:{sm:'block',xs:'none'}}}></Divider>
            <Grid sx={{display:{sm:'block',xs:'none',width:'100%'}}}>
                <a className="text-decoration-none d-flex flex-row align-items-center justify-content-center" style={{color:'#fff',height:'auto',width:'100%'}} href={"tel:"+telefono[0]}>
                    <PhoneIcon style={{fill:EstilosGlobales.colorPrimario,width:'18px'}}></PhoneIcon>
                    <Typography className="py-0 my-0" variant="caption" style={{lineHeight:"0px"}}>
                        {telefono[0]}
                    </Typography>
                </a>
            </Grid>
        </Paper> 
    </Grid>
    );
}
