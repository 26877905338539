import TituloTorEmp from '../toremp/components/TituloTorEmp'

export default function Titulo(props) {

    return(
        <TituloTorEmp size={props.size} className={props.className} titulo={props.titulo}>
            {props.children}
        </TituloTorEmp>
    );

}
