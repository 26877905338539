import { Button, Typography } from "@material-ui/core";
//import FacebookIcon from "@material-ui/icons/Facebook";
//import LinkedInIcon from "@material-ui/icons/LinkedIn";
//import YouTubeIcon from "@material-ui/icons/YouTube";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MenuDown from "./Header/MenuDown";
import MenuSuperfamilias from "./Header/MenuSuperfamilias";
import MenuBlog from "./Header/MenuBlog";
import MenuUsuario from "./Header/MenuUsuario";
import "./Header.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { Redirect } from "react-router-dom";
//import InstagramIcon from "@material-ui/icons/Instagram";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EstilosGlobales from "../../toremp/EstilosGlobales";
//import LinearProgressMine from '../LinearProgressMine'
import MenuSucursales from "./Header/MenuSucursales";

//CYBER MODE
import CyberModeContext from "../../context/CyberModeContext";

//Contexts
import Carro from "../../context/Carro";
import UsuarioClienteContext from "../../context/UsuarioClienteContext";

import number_format from "../../context/numberFormat";
//TOREMP
import ConfigMenu from "../../toremp/ConfigMenu";
import { useMediaQuery, useTheme, useScrollTrigger } from "@mui/material";
import { MobileNavStickyNav } from "./Header/MobileNavStickyNav";

export default function App(props) {
  const { DatosUsuarioCliente, IsLoged } = useContext(UsuarioClienteContext);

  const { DatosCarro, ActualizarCarro } = useContext(Carro);

  const { CyberMode, DatosCyberMode } = useContext(CyberModeContext);

  const clasesIconos = "mr-1";

  const [sugerenciasBusqueda, setsugerenciasBusqueda] = useState([]);
  const [Busqueda, setBusqueda] = useState("");
  const [BusquedaSubmited, setBusquedaSubmited] = useState("");

  const [EstadoActualizacion, setEstadoActualizacion] = useState("inicio");
  const [EstadoRedes, setEstadoRedes] = useState("inicio");

  const [DatosEmpresa, setDatosEmpresa] = useState({
    telefono: "",
    email: "",
    nombre: "",
    whatsapp: "",
    direccion: "",
    website: "",
    linkedin: "",
    facebook: "",
    youtube: "",
    instagram: "",
  });

  useEffect(() => {
    if (EstadoActualizacion === "inicio") {
      ActualizarCarroLocal();
    }
    if (EstadoRedes === "inicio") {
      cargarRedes();
    }
  });

  const cargarRedes = () => {
    setEstadoRedes("cargando");
    axios({
      url: process.env.PUBLIC_URL + "/assets/api/global/config.json",
    }).then((resp) => {
      var res = resp.data;
      setDatosEmpresa(res.Empresa);
      setEstadoRedes("cargado");
    });
  };

  const ActualizarCarroLocal = () => {
    setEstadoActualizacion("cargado");
    ActualizarCarro();
    /* console.log("Se actualizo carro"); */
  };

  const SugerirBusqueda = (Texto) => {
    if (Texto.length < 4) {
      return false;
    }

    var datos = new FormData();

    datos.append("accion", "buscar");
    datos.append("busqueda", Texto);

    axios({
      url: process.env.PUBLIC_URL + "/assets/api/buscar.php",
      method: "post",
      data: datos,
    }).then((resp) => {
      var res = resp.data;
      /* console.log(res); */
      setsugerenciasBusqueda(res);
    });
  };

  const buscarSubmit = (e) => {
    e.preventDefault();
    if (Busqueda.length <= 0) {
      return false;
    }
    /* console.log("Buscar: "+Busqueda); */
    setSubmitBuscar(true);
    setBusquedaSubmited(Busqueda);
  };

  const [SubmitBuscar, setSubmitBuscar] = useState(false);
  const [MenuUsuarioDeploy, setMenuUsuarioDeploy] = useState(false);

  //console.log(ConfigMenu);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const stylesDesktop = {
    position: "sticky",
    top: "0px",
    backgroundColor: "#fff",
  };

  const trigger = useScrollTrigger({
    threshold: 200, // Altura de la ventana
    disableHysteresis: true // Desactivar la histeresis
  });  

  return (
    <div
      style={{
        ...(!isMobile ? stylesDesktop : {}),
        zIndex: "1000",
      }}
    >
      {
        (trigger && isMobile) &&
        <MobileNavStickyNav CyberMode={CyberMode} DatosEmpresa={DatosEmpresa} DatosCyberMode={DatosCyberMode} EstilosGlobales={EstilosGlobales} ConfigMenu={ConfigMenu}></MobileNavStickyNav>
      }
      {SubmitBuscar ? (
        <Redirect
          to={"/buscar/" + encodeURIComponent(BusquedaSubmited)}
        ></Redirect>
      ) : (
        ""
      )}
      <div
        className="container-fluid border-bottom"
        style={{
          backgroundColor: EstilosGlobales.especifico.barraSuperior.fondo,
          color: EstilosGlobales.especifico.barraSuperior.texto,
        }}
      >
        <div className="row justify-content-between py-1 px-4">
          {ConfigMenu.configuracion.menu_datos &&
          ConfigMenu.configuracion.menu_datos.activado === "true" ? (
            <div className="d-flex flex-row">
              {ConfigMenu.configuracion.menu_datos.contenido.map(
                (cont, index) => {
                  return (
                    <div className="" key={index}>
                      <Typography variant="caption">
                        {cont.titulo}:{" "}
                        <a
                          href={"tel:" + DatosEmpresa.telefono}
                          className="text-decoration-none font-weight-bold"
                          style={{
                            color:
                              EstilosGlobales.especifico.barraSuperior.texto,
                          }}
                        >
                          {DatosEmpresa[cont.variable] &&
                          DatosEmpresa[cont.variable].length > 0 ? (
                            <>
                              {DatosEmpresa[cont.variable].map(
                                (contenido_var, index2) => {
                                  if (DatosEmpresa[cont.variable][index2 + 1]) {
                                    return (
                                      <span key={index}>
                                        {contenido_var + " - "}
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span key={index}>{contenido_var}</span>
                                    );
                                  }
                                }
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {/*DatosEmpresa[cont.variable]*/}
                        </a>
                      </Typography>
                      {ConfigMenu.configuracion.menu_datos.contenido[
                        index + 1
                      ] ? (
                        <span className="mx-2">|</span>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            ""
          )}
          {ConfigMenu.configuracion.menu_blog &&
          ConfigMenu.configuracion.menu_blog.activado === "true" ? (
            <MenuBlog />
          ) : (
            ""
          )}
          <div className="d-flex flex-row align-items-center">
            {ConfigMenu.configuracion.menu_iconos.boton_sucursales.activado &&
            ConfigMenu.configuracion.menu_iconos.boton_sucursales.activado ===
              "true" ? (
              <Link to="/contacto/sucursales" className="text-decoration-none">
                <div className="d-flex flex-row align-items-center mr-3">
                  <LocationOnIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                  />
                  <Typography
                    className="font-weight-bold"
                    variant="caption"
                    style={{
                      color: EstilosGlobales.especifico.barraSuperior.texto,
                    }}
                  >
                    Sucursales
                  </Typography>
                </div>
              </Link>
            ) : (
              ""
            )}
            <a
              target="_blank"
              rel="noreferrer"
              href={DatosEmpresa.whatsapp}
              className={
                clasesIconos +
                " text-decoration-none btn btn-success py-0 px-2 d-flex flex-row align-items-center"
              }
            >
              <WhatsAppIcon></WhatsAppIcon>
              <Typography className="font-weight-bold" variant="caption">
                Whatsapp
              </Typography>
            </a>

            {ConfigMenu.configuracion.menu_iconos.boton_webpay.activado &&
            ConfigMenu.configuracion.menu_iconos.boton_webpay.activado ===
              "true" ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={DatosEmpresa.webpay}
                className={
                  clasesIconos +
                  " text-decoration-none btn btn-light py-0 px-2 d-flex flex-row align-items-center"
                }
              >
                <Typography
                  variant="body2"
                  className="font-weight-bold d-flex align-items-center mr-1 mt-1"
                >
                  {ConfigMenu.configuracion.menu_iconos.boton_webpay.texto}
                </Typography>
                <img
                  alt="..."
                  className="img-fluid"
                  src={process.env.PUBLIC_URL + "/assets/imagenes/wp_logo2.png"}
                  style={{ maxHeight: "25px" }}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-2 align-items-center">
          <div className="col-md-4">
            <div style={{ maxWidth: "300px" }}>
              {CyberMode ? (
                <Link to="/">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/imagenes/cyber/" +
                      DatosCyberMode.cyber_logo
                    }
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              ) : (
                <div className="container-fluid">
                  {ConfigMenu.configuracion.tipo_logo === "1" ? (
                    <Link to="/">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/toremp/imagenes/logof.png"
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  ) : (
                    <Link
                      to="/"
                      className="d-flex flex-row align-items-center text-decoration-none"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/toremp/imagenes/logof.png"
                        }
                        alt=""
                        className="img-fluid mr-2"
                        style={{ maxWidth: "130px" }}
                      />
                      <Typography
                        variant="h4"
                        className="font-toremp text-decoration-none"
                        style={{ color: EstilosGlobales.colorTerciario }}
                      >
                        {DatosEmpresa.nombre}
                      </Typography>
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <form
              onSubmit={(e) => {
                buscarSubmit(e);
              }}
              className="d-flex flex-row align-items-center"
            >
              <Autocomplete
                className="w-100"
                id="buscador"
                freeSolo
                options={sugerenciasBusqueda.map((sugerencia) => sugerencia)}
                onInputChange={(e, inputValue) => {
                  SugerirBusqueda(inputValue);
                  setBusqueda(inputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    /* onChange={(e)=>{SugerirBusqueda(e.target.value);setBusqueda(e.target.value)}} */ style={{
                      borderColor: EstilosGlobales.colorSecundario,
                    }}
                    {...params}
                    label="Encuentra tu producto"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
              <Button
                type="submit"
                variant="contained"
                style={{
                  height: "56px",
                  backgroundColor: EstilosGlobales.colorSecundario,
                  marginLeft: "-5px",
                }}
                className="mt-2 p-0 m-0"
              >
                <SearchIcon className="text-white"></SearchIcon>
              </Button>
            </form>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-row justify-content-between">
              <Link to="/carro" className="text-decoration-none text-dark">
                <div className="d-flex flex-row">
                  <div className="position-relative">
                    <div
                      className="border shadow-sm py-2 px-2 position-relative text-white"
                      style={{
                        backgroundColor: EstilosGlobales.colorSecundario,
                        borderRadius: "12px 12px 12px 12px",
                      }}
                    >
                      <ShoppingCartIcon fontSize="small"></ShoppingCartIcon>
                    </div>
                    <div
                      className="badge badge-danger position-absolute"
                      style={{
                        top: "-4px",
                        right: "-8px",
                        backgroundColor: EstilosGlobales.colorPrimario,
                      }}
                    >
                      {DatosCarro.cantidad_productos}
                    </div>
                  </div>
                  <div>
                    <Typography
                      className="font-weight-bold ml-3"
                      variant="body2"
                    >
                      CARRO
                    </Typography>
                    <Typography className="ml-3">
                      $ {number_format(DatosCarro.total, 0, ",", ".")}
                    </Typography>
                  </div>
                </div>
              </Link>

              <div className="d-flex flex-row position-relative">
                <div className="position-relative">
                  <div
                    className="border shadow-sm py-2 px-2 position-relative text-white"
                    style={{
                      backgroundColor: EstilosGlobales.colorSecundario,
                      borderRadius: "12px 12px 12px 12px",
                    }}
                  >
                    <PersonIcon fontSize="small"></PersonIcon>
                  </div>
                </div>
                {IsLoged ? (
                  <>
                    <div
                      className="d-flex flex-row align-items-center ml-3 h-100"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setMenuUsuarioDeploy(true);
                      }}
                    >
                      <div className="btnMenu">
                        {DatosUsuarioCliente !== null ? (
                          <div className="d-flex flex-row">
                            <Typography>
                              {DatosUsuarioCliente.nombre}
                            </Typography>
                            <ArrowDropDownIcon
                              fontSize="small"
                              style={{
                                color: EstilosGlobales.colorPrimario,
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {MenuUsuarioDeploy ? (
                      <MenuUsuario
                        MenuUsuarioDeploy={{
                          setMenuUsuarioDeploy,
                          MenuUsuarioDeploy,
                        }}
                        className="position-absolute"
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div className="d-flex flex-row ml-2 align-items-center">
                    <Link
                      className="text-decoration-none text-dark"
                      to="/login"
                    >
                      <Typography className="ml-1" variant="body2">
                        LOGIN
                      </Typography>
                    </Link>
                    <span className="ml-1">/</span>
                    <Link
                      className="text-decoration-none text-dark"
                      to="/registrar"
                    >
                      <Typography className="ml-1" variant="body2">
                        REGISTRAR
                      </Typography>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {ConfigMenu.menu_categorias_root.activado &&
      ConfigMenu.menu_categorias_root.activado === "true" ? (
        <MenuSuperfamilias />
      ) : (
        ""
      )}
      {ConfigMenu.menu_sucursales.activado &&
      ConfigMenu.menu_sucursales.activado === "true" ? (
        <MenuSucursales ConfigMenu={ConfigMenu}></MenuSucursales>
      ) : (
        ""
      )}
      <MenuDown ConfigMenu={ConfigMenu}></MenuDown>
    </div>
  );
}

/*function LineaVertical() {
    const EstilosLinea = {
        height: "100%",
        width: "1px",
    };
    return <div className={"mr-1 border-right"} style={EstilosLinea}></div>;
}*/
