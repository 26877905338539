import {Typography} from '@material-ui/core'
import {useEffect,useState} from 'react'
import axios from 'axios'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
//import FacebookIcon from '@material-ui/icons/Facebook';
//import LinkedInIcon from '@material-ui/icons/LinkedIn';
//import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
//import InstagramIcon from '@material-ui/icons/Instagram';
import MetaData from '../context/MetaData'

export default function Mantencion(){
    
    const [DatosEmpresa,setDatosEmpresa] = useState({
        telefono:"",
		email:"",
		nombre:"",
		whatsapp:"",
		direccion:"",
		website:"",
		linkedin:"",
		facebook:"",
		youtube:"",
		instagram:"",
    });
    
    
    useEffect(()=>{
        const cargarDatosEmpresa=()=>{
        
            axios({
                url:process.env.PUBLIC_URL+"/assets/api/global/config.json"
            }).then((resp)=>{
                var res = resp.data;
                setDatosEmpresa(res.Empresa);
            })
        }
        cargarDatosEmpresa();
    },[])
    

    return(
        <div className="">
            <MetaData titulo="En Mantención"/>
            <div className="container">
                <div className="row my-2">
                    <div className="" style={{maxWidth:"250px"}}>
                        <img alt="..." className="img-fluid" src={ process.env.PUBLIC_URL+"/toremp/imagenes/logof.png" }/>
                    </div>
                </div>
            </div>
            <div className="bg-dark">
                <div className="container py-5" style={{minHeight:window.innerHeight}}>
                    <div className="row justify-content-center align-items-center">
                        <Typography variant="h2" className="text-white">
                            Página en Mantención
                        </Typography>
                        <Typography variant="body1" className="text-white mt-2">
                            La Página se encuentra actualmente en mantención. Puedes contactarte con nosotros por medio de nuestros canales de  comunicación que expondremos acontinuación. <b>Volveremos Pronto!</b>
                        </Typography>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="">
                            <div className="d-flex flex-row mt-1">
                                <PhoneIcon className="text-white"/>
                                <Typography className="ml-2 text-white">
                                    {DatosEmpresa.telefono}
                                </Typography>
                            </div>
                            <div className="d-flex flex-row mt-1">
                                <MailOutlineIcon className="text-white"/>
                                <Typography className="ml-2 text-white">
                                    {DatosEmpresa.email}
                                </Typography>
                            </div>
                            <div className="d-flex flex-row mt-1">
                                <LocationOnIcon className="text-white"/>
                                <Typography className="ml-2 text-white">
                                    {DatosEmpresa.direccion}
                                </Typography>
                            </div>
                            <div className="d-flex flex-row mt-1">
                                <a rel="noreferrer" href={"https://wa.me/"+DatosEmpresa.whatsapp} target="_blank" className="btn btn-success d-flex flex-row"> 
                                    <WhatsAppIcon className="text-white"/>
                                        <span className="font-weight-bold ml-2">
                                            WhatsApp
                                        </span>
                                </a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}
