import {useContext,useEffect} from 'react'
import {Typography} from '@material-ui/core'
import EstilosGlobales from '../../toremp/EstilosGlobales'
import DatosConfigContext from '../../context/config'
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export default function MenuPersonalizadoTorEmp(){
    const {DatosConfig} = useContext(DatosConfigContext);

    useEffect(()=>{
        //console.log(DatosConfig.Empresa);
    },[])

    return(
        <div>
            {DatosConfig && DatosConfig.Empresa.sucursales?
            <div className="row justify-content-center">
                {
                    DatosConfig.Empresa.sucursales.map((sucursal,index)=>{
                        return(
                            <div className="col-md-3 col-lg-2 col-6 px-2 my-1" key={index}>
                                <div className="px-2 text-center text-white">
                                    <Typography className="font-weight-bold mb-0 pb-0">
                                        {sucursal.nombre}
                                    </Typography>
                                    <div className="font-weight-bold py-0 my-0">
                                        <LocationOnIcon style={{fontSize:"1rem",color:EstilosGlobales.colorPrimario}}/>
                                        <Typography variant="caption" className="text-white">
                                            {sucursal.direccion}
                                        </Typography>
                                    </div>
                                    <div className="d-flex flex-column">
                                    {
                                        sucursal.telefono.map((numero,index)=>{
                                            return(
                                                <div key={index} className="font-weight-bold py-0 my-0">
                                                    <PhoneIcon style={{fontSize:"1rem",color:EstilosGlobales.colorPrimario}}/>
                                                    <Typography variant="caption" className="text-white">
                                                        {numero}
                                                    </Typography>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>:""
            }
            <LogosRepresentaciones/>            
        </div>
    );
}

function LogosRepresentaciones(){

    var imagenesRepresentaciones = [
        {imagen:"LOGO GATOR.jpg"},
        {imagen:"LOGO MAXICARGO.jpg"},
        {imagen:"LOGO MESICA.jpg"},
        {imagen:"LOGO PYROPOL.jpg"}
    ]

    return(
        <div className="mt-2">
            <div className="row justify-content-center">
                {
                    imagenesRepresentaciones.map((logo,index)=>{
                        return(
                            <Logo imagen={ process.env.PUBLIC_URL+"/toremp/imagenes/footer/"+logo.imagen }/>
                        );
                    })
                }
            </div>
        </div>
    );
}

function Logo(props){
    return(
        <div className="col-md-2 col-sm-3 col-4 mt-2">
            <div className="bg-white px-1 border rounded shadow-sm text-center d-flex align-items-center justify-content-center" style={{minHeight:"90px"}}>
                <img className="img-fluid" src={props.imagen} style={{maxHeight:"90px"}}/> 
            </div>
        </div>
    );
}
