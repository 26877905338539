import React from "react";
import { Grid,Typography } from "@mui/material";
import MenuDown from "./MenuDown";
import MenuSucursales from "./MenuSucursales";
import { Link } from "react-router-dom/cjs/react-router-dom";

const LogoContainer = ({
  CyberMode,
  DatosCyberMode,
  ConfigMenu,
  DatosEmpresa,
  EstilosGlobales,
}) => {
  return (
    <div className="container">
      <div className="row py-2 align-items-center">
        <div className="col-md-4">
          <div style={{ maxWidth: "300px" }}>
            {CyberMode ? (
              <Link to="/">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/imagenes/cyber/" +
                    DatosCyberMode.cyber_logo
                  }
                  alt=""
                  className="img-fluid"
                />
              </Link>
            ) : (
              <div className="container-fluid">
                {ConfigMenu.configuracion.tipo_logo === "1" ? (
                  <Link to="/">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/toremp/imagenes/logof.png"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                ) : (
                  <Link
                    to="/"
                    className="d-flex flex-row align-items-center text-decoration-none"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/toremp/imagenes/logof.png"
                      }
                      alt=""
                      className="img-fluid mr-2"
                      style={{ maxWidth: "130px" }}
                    />
                    <Typography
                      variant="h4"
                      className="font-toremp text-decoration-none"
                      style={{ color: EstilosGlobales.colorTerciario }}
                    >
                      {DatosEmpresa.nombre}
                    </Typography>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileNavStickyNav = (props) => {
  return (
    <Grid
      style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        zIndex: "1200",
        width: "100vw",
        backgroundColor: "#fff",
      }}
    >
      <LogoContainer {...props}></LogoContainer>
      <MenuSucursales ConfigMenu={props?.ConfigMenu}></MenuSucursales>
      <MenuDown ConfigMenu={props?.ConfigMenu}></MenuDown>
    </Grid>
  );
};
