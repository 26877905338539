import React from 'react'
import { useState, useEffect,useContext } from 'react'
import { List, ListItemText, Typography,Button } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import RoomIcon from '@material-ui/icons/Room';
import axios from 'axios';
import EstilosGlobales from '../../toremp/EstilosGlobales.jsx'
import DatosConfigContext from '../../context/config'
//TOREMP
import MenuPersonalizadoTorEmp from '../../toremp/components/MenuPersonalizadoTorEmp'
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme

function MiembroLista(props) {
    return (
        <ListItemText>
            <Link className="text-decoration-none text-white" to={props.ruta}>
                <ArrowRightIcon style={{ color: EstilosGlobales.colorPrimario }} fontSize="small"></ArrowRightIcon>
                {props.children}
            </Link>
        </ListItemText>
    );
}

function MiembroListaLink(props) {
    return (
        <ListItemText>
            <a target="_blank" href={props.ruta} className="text-decoration-none text-white">
                <ArrowRightIcon style={{ color: EstilosGlobales.colorPrimario }} fontSize="small"></ArrowRightIcon>
                {props.children}
            </a>
        </ListItemText>
    );
}

export default function Footer() {
    const {DatosConfig} = useContext(DatosConfigContext);
    const [ClaseColFooter,setClaseColFooter] = useState("col-md-6 col-lg-3 my-2");

    const [SeccionesEmp,setSeccionesEmp ] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Acerca de Nosotros", ruta: "/acercaden/acercaden" },
        { nombre: "Productos", ruta: "/productos" },
        { nombre: "Contacto", ruta: "/contacto" }
    ]);

    const [Estado, setEstado] = useState("inicio");
    const [InfoGlobal, setInfoGlobal] = useState();

    const [InformacionEmpresaGlobal,setInformacionEmpresaGlobal] = useState();
    const [InfoFooterContacto,setInfoFooterContacto] = useState();

    useEffect(() => {
        if (Estado === "inicio") {
            cargarInfoGlobal();
        }
    })

    const cargarInfoGlobal = () => {

        setEstado("cargando");
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/global/config.json"
        }).then((resp) => {
            var res = resp.data;
            setInfoGlobal(res);
            setEstado("cargado");
        })
    }

    const [URLterminos_condiciones,setURLterminos_condiciones] = useState("#");
    useEffect(()=>{
        const cargarLinkProductos=()=>{
            var datos = new FormData();
            datos.append("accion","cargar_link");
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/productos_link_catalogo.php",
                method:"post",
                data:datos
            }).then((resp)=>{
                var res = resp.data;
                //console.log(res);
                if(res[0]==="realizado"){
                    var link_superfamilia = encodeURIComponent(res['link_productos']['superfamilia']);
                    var link_familia= encodeURIComponent(res['link_productos']['familia']);
                    var link_subfamilia= encodeURIComponent(res['link_productos']['subfamilia']);
                    setSeccionesEmp([
                        { nombre: "Inicio", ruta: "/" },
                        { nombre: "Acerca de Nosotros", ruta: "/acercaden/acercaden" },
                        { nombre: "Productos", ruta: "/productos/"+link_superfamilia+"/"+link_familia+"/"+link_subfamilia+"/superfamilia"},
                        { nombre: "Contacto", ruta: "/contacto" }
                    ]);
                }
                
            })
        }
        
        const cargarInformacionEmpresaFooterContacto=()=>{
            var datos = new FormData();
            datos.append("accion","cargar_informacion_empresa");
            
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/informacion_empresa_footer_contacto_cargar.php",
                method:"post",
                data:datos
            }).then((resp)=>{
                var res = resp.data;
                if(res[0]==="realizado"){
                    setInformacionEmpresaGlobal(res.horarios);
                    setInfoFooterContacto(res.footer_contacto);
                    if((res.horarios.activado)<1 && (res.footer_contacto.activado<1)){
                        setClaseColFooter("col-md-6 col-lg-4 my-2");
                    }
                }
            })        
        }

        const cargarTerminosyCondiciones=()=>{
             
            
            axios({
                url: process.env.PUBLIC_URL+"/assets/api/venta/link_terminocondiciones.php" ,
            }).then((resp)=>{
                var res = resp.data;
                if(res[0]==="realizado"){
                    setURLterminos_condiciones(res['link_terminocondiciones']);
                }
            })
        }

        cargarLinkProductos();
        cargarInformacionEmpresaFooterContacto();
        cargarTerminosyCondiciones();
    },[])

    return (
        <div style={{ background: EstilosGlobales.especifico.footer.fondo }}>
            <div className="container pt-5">
                <div className="row">
                    <div className={ClaseColFooter}>
                        <div style={{ maxWidth: "300px" }}>
                            <img src={process.env.PUBLIC_URL+"/toremp/imagenes/logofblanc.png"} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className={ClaseColFooter}>
                        <Typography variant="body1" className="text-white font-weight-bold">SECCIONES {DatosConfig.Empresa.nombre}</Typography>
                        {SeccionesEmp?
                            <List className="text-white">
                                {
                                    SeccionesEmp.map((Seccion, index) => {
                                        return (
                                            <MiembroLista key={index} ruta={Seccion.ruta}>{Seccion.nombre}</MiembroLista>
                                        );
                                    })
                                }
                                {
                                    URLterminos_condiciones && URLterminos_condiciones.length>0?
                                    <MiembroListaLink ruta={URLterminos_condiciones}>
                                        Términos y condiciones
                                    </MiembroListaLink>:""
                                }
                            </List>:""
                        }
                    </div>
                    <div className={ClaseColFooter}>
                        <Typography variant="body1" className="text-white font-weight-bold">CONTACTO</Typography>
                        {
                            InfoGlobal ?
                                <List className="text-white">
                                    {
                                        InfoGlobal.Empresa.email && InfoGlobal.Empresa.email.length>0?
                                        <React.Fragment>
                                            {
                                                InfoGlobal.Empresa.email.map((email,index)=>{
                                                    return(
                                                        <ListItemText key={index}>
                                                            <MailOutlineIcon fontSize="small"></MailOutlineIcon>
                                                            <span className="ml-2">{email}</span>
                                                        </ListItemText>

                                                    )
                                                })
                                            }
                                        </React.Fragment>:""
                                    }
                                    {
                                        InfoGlobal.Empresa.telefono && InfoGlobal.Empresa.telefono.length>0?
                                        <React.Fragment>
                                            {
                                                InfoGlobal.Empresa.telefono.map((telefono,index)=>{
                                                    return(
                                                        <ListItemText key={index}>
                                                            <PhoneEnabledIcon fontSize="small"></PhoneEnabledIcon>
                                                            <span className="ml-2">{telefono}</span>
                                                        </ListItemText>

                                                    )
                                                })
                                            }
                                        </React.Fragment>:""
                                    }
                                    <ListItemText>
                                        <RoomIcon fontSize="small"></RoomIcon>
                                        <span className="ml-2">{InfoGlobal.Empresa.direccion}</span>
                                    </ListItemText>
                                </List> : ""
                        }
                    </div>
                    {
                        InformacionEmpresaGlobal && InformacionEmpresaGlobal.activado && parseInt(InformacionEmpresaGlobal.activado)>0 ?
                        <div className={ClaseColFooter}>
                            <Typography variant="body1" className="text-white font-weight-bold">INFORMACIÓN</Typography>
                            <HorariosAtencion Contenido={InformacionEmpresaGlobal.contenido}/>
                        </div>
                        :""
                    }
                    {
                        InfoFooterContacto && InfoFooterContacto.activado && parseInt(InfoFooterContacto.activado)>0 ?
                        <div className={ClaseColFooter}>
                            <Typography variant="body1" className="text-white font-weight-bold">NUESTRAS REDES</Typography>
                            <FooterContacto/>
                        </div>
                        :""
                    }
                </div>
                <MenuPersonalizadoTorEmp/>
                <div className="row justify-content-center py-3">
                    {InfoGlobal?
                        <div className="text-white">
                            Desarrollado por <a className="text-white text-decoration-none" href={InfoGlobal.Empresa_desarrollo.pagina}>{InfoGlobal.Empresa_desarrollo.nombre}</a> &copy; {(new Date().getFullYear())}
                        </div>:""
                    }
                </div>
            </div>
        </div>
    );
}

const HorariosAtencion = (props) => {
    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarHorario=()=>{
        
            /*
            axios({
                url:process.env.PUBLIC_URL+"/assets/api/horarios_cargar.php"
            }).then((resp)=>{
                var res = resp.data;            
                if(res){
                    quill.setContents(res);
                    Estado="cargado";
                }
            })*/
            quill.setContents(JSON.parse(props.Contenido));
        }

        if(Estado==="inicio"){
            if(quill){
                cargarHorario();
                quill.disable();
            }
        }   
        
        
        
    }, [quill]);

    

    return (
        <div className="text-white border-0">
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
};

function FooterContacto(){
    const {DatosConfig} = useContext(DatosConfigContext);
    const [EmailContacto,setEmailContacto] = useState("");

    return(
        <div className="">
            <div className="">
                <div className="d-flex mt-2">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                            DatosConfig.Empresa.whatsapp
                        }
                        className={
                            "mr-1" +
                            " text-decoration-none btn btn-success py-0 px-2 d-flex flex-row align-items-center"
                        }
                    >
                        <WhatsAppIcon></WhatsAppIcon>
                        <Typography
                            className="font-weight-bold"
                            variant="caption"
                        >
                            Whatsapp
                        </Typography>
                    </a>
                </div>
                <div className="row">
                    {
                        DatosConfig.Empresa.facebook && DatosConfig.Empresa.facebook.length>0?
                        <a className="position-relative" href={DatosConfig.Empresa.facebook}>
                            <FacebookIcon className="position-relative" style={{zIndex:"100"}}/> 
                            <div className="position-absolute" style={{zIndex:"20", top:"5px",left:"5px",backgroundColor:"#fff",width:"15px",height:"18px"}}></div>
                        </a>:""
                    }
                    {
                        DatosConfig.Empresa.instagram && DatosConfig.Empresa.instagram.length>0?
                        <a className="position-relative text-center" href={DatosConfig.Empresa.instagram}>
                            <InstagramIcon className="position-relative text-white" style={{zIndex:"100",padding:"4px 4px 4px 4px"}}/> 
                            <div className="position-absolute rounded" style={{zIndex:"20", top:"5px",left:"4px",background:"radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",width:"16px",height:"18px"}}></div>
                        </a>:""
                    }
                </div>
                <div className="row mt-2">
                    <div className="d-flex flex-row position-relative">
                        <input 
                            onChange={(e)=>{
                                setEmailContacto(e.target.value);
                            }} 
                            className="form-control" placeholder="nombre@email.com"
                        />
                        <Link onClick={()=>{window.scroll(0,0);}} to={"/contacto/"+EmailContacto}>
                            <Button variant="contained" size="small" className="px-0 position-absolute" style={{height:"38px",width:"20px",backgroundColor:EstilosGlobales.colorSecundario,right:"-40px"}}>
                                <EmailIcon className="px-0 mx-0 text-white"/>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div> 
        </div>
    );
}
