import Ruta from '../Ruta'
import Titulo from '../Titulo'
import {Link, useParams} from 'react-router-dom'
import {useState,useEffect} from 'react'
import { CircularProgress, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import EstilosGlobales from '../../toremp/EstilosGlobales'

const colorPrimario = EstilosGlobales.colorPrimario;

export default function ContenidoCarroVerEt3Fail(){
    let {tipoerror} = useParams();

    const [Estado,setEstado] = useState("inicio");
    
    const [Error,setError] = useState();
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarError();
        }
    })
    
    const cargarError=()=>{
        setEstado("cargando");
        
        setError(<ErrorPrint tipoerror={tipoerror}></ErrorPrint>);
        setEstado("cargado");
        
    }   

    return(
        <div>
            <Ruta 
                ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Venta"}
                ]}
            >                
            </Ruta>
            <div className="bg-light py-3">                    
                <div className="container">
                    <Titulo titulo="Venta" className="py-3"></Titulo>
                    <div className="bg-white rounded border shadow-sm" style={{minHeight:window.innerHeight*0.6}}>
                        {Error}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ErrorPrint(props){

    let {tipoerror}=props;
    if(tipoerror==="er_decl_erro"){
        return(
            
            <div className="row m-0 justify-content-center py-4">
                <div>
                    <Typography className="text-danger font-weight-bold" variant="h5">Orden de Compra Cancelada o Anulada</Typography>
                    <div>
                        <Typography className="font-weight-bold my-2">Estimado Cliente:</Typography>
                        <Typography>
                        Ha anulado el proceso de pago via Webpay. Si desea volver a intentar, ingrese en "Volver a intentar".
                        </Typography>
                        <div className="d-flex flex-row justify-content-center mt-4">
                            <Link className="text-decoration-none" to="/carroet2">
                                <Button size="large" className="text-white font-weight-bold" style={{backgroundColor:colorPrimario}}>Volver a Intentar</Button>
                            </Link>
                        </div>
                    </div>
                </div>                
            </div>
            
        );
    }else if(tipoerror==="er_rech"){
        return(
            
            <div className="row m-0 justify-content-center py-4">
                <div>
                    <Typography className="text-danger font-weight-bold" variant="h5">Orden de Compra Rechazada</Typography>
                    <div>
                        <Typography className="my-3"><b>Estimado Cliente</b>, las posibles causas de este rechazo son:</Typography>                        
                        <Typography variant="body2" className="text-danger font-weight-bold ml-2">
                            * Error en el ingreso de los datos de su tarjeta de Crédito o Débito (fecha y/o código de seguridad).<br />
                                * Su tarjeta de Crédito o Débito no cuenta con saldo suficiente.<br />
                                * Tarjeta aún no habilitada en el sistema financiero.
                        </Typography>
                        <div className="d-flex flex-row justify-content-center mt-4">
                            <Link className="text-decoration-none" to="/carroet2">
                                <Button size="large" className="text-white font-weight-bold" style={{backgroundColor:colorPrimario}}>Volver a Intentar</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        );
    }else{
        return(
            <div className="row m-0 justify-content-center py-4 mt-5">
                <CircularProgress style={{color:colorPrimario}}></CircularProgress>
            </div>
        );
    }
}