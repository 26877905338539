import {Link} from 'react-router-dom'

const ConfigModulos={
    CarroCompras:{
        agregar_carro:{
            mensajes:{
                sin_stock:{
                    html:<div><br/><b>Consulte</b> con nuestros vendedores.</div>
                }
            }
        }
    }
}

export default ConfigModulos;
