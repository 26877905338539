import {useState,useEffect} from 'react'
import { Typography,Button } from "@material-ui/core"
import MenuCategorias from './MenuCategorias'
import ListIcon from '@material-ui/icons/List';
import {Link} from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import EstilosGlobales from '../../../toremp/EstilosGlobales'
//import SeccionMenu from '../../../toremp/contenido/index.json'
import ConfigMenu from '../../../toremp/ConfigMenu'

export default function MenuDown(props){
    let {ConfigMenu} = props;

    const ClaseBotones = "botonMenuDown text-white mr-1 py-2"
    const [Menu,setMenu] = useState(ConfigMenu.menu_down.estructura);
    const propsanim = useSpring({ opacity: 1, from: { opacity: 0 } });
    const [DropPrincipal,setDropPrincipal] = useState(false);

    const [DropDownEmpresa,setDropDownEmpresa] = useState(false);
    const togleDropPrincipal=()=>{
        setDropPrincipal(!DropPrincipal);
    }
    
    const [Cargado,setCargado] = useState(false);

    useEffect(()=>{
        const cargarMenu=()=>{
            var submenu_empresa=[];
            var index_acercaden = 0;
            var menu_def = Menu;

            /*SeccionMenu.secciones.map((seccion_submenu)=>{
                submenu_empresa.push({titulo:seccion_submenu.titulo,linkto:"/acercaden/"+seccion_submenu.ruta});
                return <></>;
            })     */

            /*ConfigMenu.menu_down.estructura.map((menu,index)=>{
                if(menu.titulo==="-" && menu.titulo!=="Inicio"){//Identificacion
                    index_acercaden=index;
                }
            })

            menu_def[index_acercaden]=
            {titulo:SeccionMenu.tituloBtn,linkto:"/acercaden",tipo:"dropdown",
               submenu:submenu_empresa
            };*/
            
            setMenu(menu_def);
            
            /*setMenu([
                {titulo:"Inicio",linkto:"/",tipo:"default"},
                {titulo:"Secciones",linkto:"/blogsec",tipo:"default"},
                {titulo:SeccionMenu.tituloBtn,linkto:"/acercaden",tipo:"dropdown",
                   submenu:submenu_empresa
                },
                {titulo:"Contacto",linkto:"/contacto",tipo:"default"},
                {titulo:"Tracking",linkto:"/tracking",tipo:"default"}
            ])*/
            setCargado(true);
        }

        if(!Cargado)
        cargarMenu();
    },[])

    /*useEffect(()=>{
        const cargarMenuEmpresa=()=>{
            
            axios({
                url: process.env.PUBLIC_URL + "/toremp/contenido/index.json",
            }).then((resp)=>{
                var res = resp.data;
                var submenu_empresa=[];
                res.secciones.map((seccion_submenu)=>{
                    submenu_empresa.push({titulo:seccion_submenu.titulo,linkto:"/acercaden/"+seccion_submenu.ruta});
                    return <></>;
                })     
                setMenu([
                    {titulo:"Inicio",linkto:"/",tipo:"default"},
                    {titulo:"Secciones",linkto:"/blogsec",tipo:"default"},
                    {titulo:res.tituloBtn,linkto:"/acercaden",tipo:"dropdown",
                       submenu:submenu_empresa
                    },
                    {titulo:"Contacto",linkto:"/contacto",tipo:"default"},
                    {titulo:"Tracking",linkto:"/tracking",tipo:"default"}
                ])
                setCargado(true);
            })
        }

        cargarMenuEmpresa();
    
    },[])*/

    return(
        <div className="container-fluid barra_menu" style={{paddingLeft:"0px",paddingRight:"0px",backgroundColor:EstilosGlobales.colorSecundario}}>
            <div className="container">
                <div className="row justify-content-between justify-content-lg-center py-0">
                    {ConfigMenu.menu_down.menu_categorias.activado==="true"?<MenuCategorias></MenuCategorias>:""}
                    {
                        Cargado?
                                <div className="py-1 d-none d-lg-flex flex-row">
                                    {
                                        Menu.map((itemMenu,index)=>{
                                            if(itemMenu.tipo==="dropdown"){
                                                return(
                                                    <div className="position-relative"
                                                        onMouseEnter={()=>{
                                                            setDropDownEmpresa(true);
                                                        }}
                                                        onMouseLeave={()=>{
                                                            setDropDownEmpresa(false);
                                                        }}
                                                        key={index}
                                                    >
                                                        <Button className={ClaseBotones}>
                                                            <Typography variant="body2">
                                                                {itemMenu.titulo}
                                                            </Typography>
                                                        </Button>
                                                            {
                                                                DropDownEmpresa?
                                                                <div className="position-absolute bg-white px-3 py-2 rounded shadow-sm text-center" style={{minWidth:"120px",top:"35px",left:"0px",zIndex:"800"}}>
                                                                    {
                                                                        itemMenu.submenu.map((submenu,index)=>{
                                                                            return(
                                                                                <Link key={index} to={submenu.linkto} className="pt-2 text-decoration-none">
                                                                                    <Typography className="d-block py-1" variant="body2" style={{color:EstilosGlobales.colorSecundario}}>
                                                                                        {submenu.titulo}
                                                                                    </Typography>
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>:""
                                                            }
                                                    </div>
                                                    
                                                )
                                            }else{
                                                return(
                                                    <Link className="text-decoration-none mx-1" key={index} to={itemMenu.linkto}>
                                                        <Button className={ClaseBotones}>
                                                            <Typography variant="body2">
                                                                {itemMenu.titulo}
                                                            </Typography>
                                                        </Button>
                                                    </Link>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            :
                            <div className="" style={{background:EstilosGlobales.especifico.menuDown.fondo,height:"44px"}}>
                            </div>
                    }
                    <div className="py-1 d-block d-lg-none position-relative">
                        <Button onClick={togleDropPrincipal} className={ClaseBotones+" position-relative"}>
                            
                            {
                                DropPrincipal?  
                                <animated.div style={propsanim}>
                                    <CloseIcon></CloseIcon>
                                </animated.div>                              
                                :
                                <animated.div style={propsanim}>
                                    <ListIcon></ListIcon>
                                </animated.div>                              
                            }
                        </Button>  
                        {
                            DropPrincipal?
                            <ClickAwayListener onClickAway={togleDropPrincipal}>
                                <div className="position-absolute bg-light shadow rounded py-2 px-4" style={{top:"45px",right:"20px",zIndex:"1000"}}>
                                    <animated.div style={propsanim}>
                                        {
                                            Menu.map((itemMenu,index)=>{
                                                return(
                                                    <Link className="text-decoration-none" key={index} to={itemMenu.linkto}>
                                                        <Button className={"text-dark"}>
                                                            <Typography variant="body2">
                                                                {itemMenu.titulo}
                                                            </Typography>
                                                        </Button>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </animated.div>
                                </div> 
                            </ClickAwayListener>  
                            :""
                        }                   
                    </div>
                </div>
            </div>            
        </div>
    );
}
