import { useState, useEffect } from "react";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { Typography } from "@material-ui/core";
import SubPostBlog from "./SubPostBlog";
import QuillBox from "../QuillBox";
import axios from "axios";
import BlogPostSideMenu from "./BlogPostSideMenu";
import { useParams, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import EstilosGlobales from '../../toremp/EstilosGlobales'
//Meta
import MetaData from '../../context/MetaData.jsx'

export default function ContenidoBlog() {
    let history = useHistory();
    let { ID_post } = useParams();
    const [Rutas, setRutas] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Blog" },
    ]);
    const [DatosPost, setDatosPost] = useState({});
    const [ContenidoPost, setContenidoPost] = useState("");

    const [PostsRelacionados, setPostsRelacionados] = useState([]);

    const [Cargado, setCargado] = useState(false);

    useEffect(() => {
        const cargarContenidoPost = (contenido_post) => {
            //console.log("Cargando Contenido Post");
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/datos/blog/posts/" +
                    contenido_post,
            }).then((resp) => {
                var res = resp.data;
                setContenidoPost(res);
                //console.log(res);
            });
        };

        const cargarDatosPost = () => {
            var datos = new FormData();
            datos.append("ID_post", ID_post);
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/blog/cargar_datos_post.php",
                method: "post",
                data: datos,
            }).then((resp) => {
                var res = resp.data;
                //console.log(res);
                if (res[0] === "realizado") {
                    setDatosPost(res["datos_post"]);
                    cargarContenidoPost(res["datos_post"]["contenido"]);
                    setCargado(true);
                    setRutas([
                        { nombre: "Inicio", ruta: "/" },
                        { nombre: "Blog" },
                        { nombre: res["datos_post"]["categoria"] },
                        { nombre: res["datos_post"]["titulo"] },
                    ]);
                } else {
                    history.push("/");
                }
            });
        };

        const cargarPostsRelacionados = () => {
            var datos = new FormData();
            datos.append("ID_post", ID_post);
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/blog/cargar_posts_relacionados.php",
                method: "post",
                data: datos,
            }).then((resp) => {
                var res = resp.data;
                if (res[0] === "realizado") {
                    setPostsRelacionados(res["posts_relacionados"]);
                }else{
                    setPostsRelacionados([]);
                }
            });
        };

        cargarDatosPost();
        cargarPostsRelacionados();
    }, [ID_post,history]);

    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div
                className="bg-light"
                style={{ minHeight: window.innerHeight * 0.6 }}
            >
                <div className="container-fluid px-0">
                    {Cargado ? (
                        <div
                            className="position-relative overflow-hidden w-100"
                            style={{ minHeight: "280px" }}
                        >
                            <MetaData titulo={DatosPost.titulo}/>
                            <img
                                className="img-fluid w-100 position-absolute"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/imagenes/blog/posts/" +
                                    DatosPost.imagen_portada
                                }
                                alt="..."
                                style={{
                                    zIndex: "5",
                                    transform: "translate(0px,-50%)",
                                    top: "50%",
                                    filter: "blur(15px)",
                                }}
                            />
                            <div
                                className="position-absolute h-100 w-100"
                                style={{
                                    zIndex: "10",
                                    backgroundColor: EstilosGlobales.colorSecundario,
                                    opacity: "0.3",
                                }}
                            ></div>
                            <div
                                className="position-absolute w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                style={{ top: "0", zIndex: 20 }}
                            >
                                <div className="position-relative">
                                    <Typography
                                        className="text-white font-weight-bold"
                                        variant="h3"
                                    >
                                        {DatosPost.titulo}
                                    </Typography>
                                    <Typography
                                        className="text-white"
                                        variant="h5"
                                    >
                                        {DatosPost.subtitulo}
                                    </Typography>
                                </div>
                                <div
                                    className="ImgPost overflow-hidden shadow-sm position-relative ml-4"
                                    style={{
                                        maxWidth: "300px",
                                        height: "200px",
                                        borderRadius: "12px 12px 12px 12px",
                                    }}
                                >
                                    <img
                                        className="img-fluid position-relative"
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/imagenes/blog/posts/" +
                                            DatosPost.imagen_portada
                                        }
                                        style={{
                                            top: "50%",
                                            transform: "translate(0,-50%)",
                                        }}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Skeleton
                            variant="rect"
                            height={280}
                            className="w-100"
                        />
                    )}
                </div>
                <div className="container">
                    <div className="row py-3">
                        <div className="col-md-9">
                            <div className="w-100 text-right">
                                <Typography
                                    variant="body2"
                                    className="text-secondary pr-3"
                                >
                                    <i>Publicado el {DatosPost.fecha}</i>
                                </Typography>
                            </div>
                            <QuillBox contenido={ContenidoPost}></QuillBox>
                        </div>
                        <BlogPostSideMenu className="col-md-3 d-none d-md-block" />
                    </div>
                </div>
                {PostsRelacionados.length > 0 ? (
                    <div className="container">
                        <div className="py-2">
                            <Titulo>Relacionado</Titulo>
                        </div>
                        <div className="row py-4 px-2">
                            {PostsRelacionados.map((subpost, index) => {
                                return (
                                    <SubPostBlog
                                        key={index}
                                        DatosPost={subpost}
                                        className="col-md-3 my-2"
                                    ></SubPostBlog>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}
