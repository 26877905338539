import { Typography } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from "react-router-dom";
import EstilosGlobales from '../toremp/EstilosGlobales.jsx' 

export default function Ruta(props){

    var Rutas = [{}];

    if(props.ruta){
        Rutas = props.ruta;
    }
    

    

    return(
        <div className={"py-2 "+props.className} style={{backgroundColor:EstilosGlobales.especifico.barraRuta.fondo}}>
            <div className="container">
                <div className="row px-2 px-md-0">
                    {
                        Rutas?
                        <div className="d-flex flex-row" style={{color:EstilosGlobales.especifico.barraRuta.texto}}>
                            {Rutas.map((elemento,index)=>{
                                return(
                                    <div key={index}>
                                        <ElementoRuta ruta={elemento.ruta}>
                                            {elemento.nombre}
                                            
                                        </ElementoRuta>
                                        {
                                            Rutas[index+1]?
                                            <ArrowRightIcon fontSize="small"></ArrowRightIcon>:
                                            ""
                                        }
                                    </div>
                                );
                            })}  
                        </div>
                        :
                        ""
                    }                                      
                </div>
            </div>
        </div>
    );
}

function ElementoRuta(props){

    var LinkRuta = "";

    if(props.ruta){
        LinkRuta = props.ruta;
    }

    return(
        <span>  
            {
                LinkRuta?
                <Link to={LinkRuta} style={{color:EstilosGlobales.especifico.barraRuta.texto}}>
                    <Typography variant="caption">
                        {props.children}
                    </Typography>
                </Link> 
                :
                <Typography variant="caption">{props.children}</Typography>  
            }       
        </span>
    );
}
