import { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Card, CardActionArea } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import EstilosGlobales from "../../../toremp/EstilosGlobales";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ClickAwayListener } from "@material-ui/core";
import UsuarioClienteContext from "../../../context/UsuarioClienteContext.jsx";

export default function MenuUsuario(props) {
    const { logoutCuenta } = useContext(UsuarioClienteContext);
    const MenuRutas = [
        {
            nombre: "Mi Cuenta",
            ruta: "/micuenta/perfil",
            icono: (
                <AccountCircleIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                    fontSize="small"
                />
            ),
        },
        {
            nombre: "Favoritos",
            ruta: "/micuenta/favoritos",
            icono: (
                <FavoriteIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                    fontSize="small"
                />
            ),
        },
        {
            nombre: "Compras",
            ruta: "/micuenta/compras",
            icono: (
                <ShoppingBasketIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                    fontSize="small"
                />
            ),
        },
    ];

    const handleLogout = () => {
        logoutCuenta();
    };

    let { setMenuUsuarioDeploy, MenuUsuarioDeploy } = props.MenuUsuarioDeploy;
    return (
        <ClickAwayListener
            onClickAway={() => {
                if (MenuUsuarioDeploy) setMenuUsuarioDeploy(false);
            }}
        >
            <div
                className={props.className}
                style={{ top: "35px", left: "0px", zIndex: "200" }}
            >
                <Card className="bg-white py-3 d-flex flex-column">
                    {MenuRutas.map((menu, index) => {
                        return (
                            <Link
                                to={menu.ruta}
                                className="text-decoration-none"
                                key={index}
                            >
                                <CardActionArea className="px-4 py-1">
                                    <div className="d-flex flex-row">
                                        {menu.icono}
                                        <Typography
                                            className="ml-3"
                                            variant="body2"
                                            style={{
                                                color:
                                                    EstilosGlobales.colorSecundario,
                                            }}
                                        >
                                            {menu.nombre}
                                        </Typography>
                                    </div>
                                </CardActionArea>
                            </Link>
                        );
                    })}
                    <div
                        onClick={handleLogout}
                        className="my-1"
                        style={{ cursor: "pointer" }}
                    >
                        <CardActionArea className="px-4">
                            <div className="d-flex flex-row ">
                                <ExitToAppIcon
                                    style={{
                                        color: EstilosGlobales.colorPrimario,
                                    }}
                                    fontSize="small"
                                />
                                <Typography
                                    className="ml-3"
                                    variant="body2"
                                    style={{
                                        color: EstilosGlobales.colorSecundario,
                                    }}
                                >
                                    Logout
                                </Typography>
                            </div>
                        </CardActionArea>
                    </div>
                </Card>
            </div>
        </ClickAwayListener>
    );
}
