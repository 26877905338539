import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { useState, useEffect, useContext } from "react";

import { Typography, Button, CircularProgress,Checkbox} from "@material-ui/core";
import number_format from "../../context/numberFormat";

import Carro from "../../context/Carro";
import LinearProgressMine from "../LinearProgressMine";
import FormularioCarroEt2 from "./FormularioCarroEt2";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "../../context/SwalAlert";
import EstilosGlobales from '../../toremp/EstilosGlobales.jsx'

//context
import UsuarioClienteContext from "../../context/UsuarioClienteContext";

export default function ContenidoCarro() {
    const { IsLoged } = useContext(UsuarioClienteContext);

    const [Region, setRegion] = useState("");
    const [LocalidadDisabled, setLocalidadDisabled] = useState(true);
    const [Localidades, setLocalidades] = useState([]);
    const { DatosCarro } = useContext(Carro);
    const [Estado, setEstado] = useState("inicio");
    const [DatosVenta, setDatosVenta] = useState({
        tipo_despacho: "",
        tipo: "Boleta",
        nombre: "",
        RUT: "",
        telefono: "",
        ciudad: "",
        direccion: "",
        email: "",
        razon_social: "",
        RUT_razon_social: "",
        segperson_nombre: "",
        segperson_RUT: "",
        segperson: false,
        direccion_facturacion: ""
    });

    const [DatosNoSubmit, setDatosNoSubmit] = useState({
        peso_despacho: 0,
        total_despacho: 0,
        plazo_despacho: "",
        productos_no_despachables: [],
    });

    const [ErrorCampos, setErrorCampos] = useState({
        nombre: { error: false, texto_error: "" },
        RUT: { error: false, texto_error: "" },
        telefono: { error: false, texto_error: "" },
        ciudad: { error: false, texto_error: "" },
        direccion: { error: false, texto_error: "" },
        email: { error: false, texto_error: "" },
        razon_social: { error: false, texto_error: "" },
        RUT_razon_social: { error: false, texto_error: "" },
        segperson_nombre: { error: false, texto_error: "" },
        segperson_RUT: { error: false, texto_error: "" },
        direccion_facturacion: { error: false, texto_error: "" }
    });

    const [EstadoForm, setEstadoForm] = useState({
        Submited: false,
        action: "",
    });

    const [CheckTerminosCondiciones,setCheckTerminosCondiciones] = useState(false);
    const [CargaTerminosCondiciones,setCargaTerminosCondiciones] = useState(false);
    const [LinkTerminosCondiciones,setLinkTerminosCondiciones]=useState("");
    const [ErrorTerminoCondiciones,setErrorTerminoCondiciones] = useState({
        error:false,
        texto:""
    });

    useEffect(() => {
        if (Estado === "inicio") {
            cargarSeccion();
        }
        if(!CargaTerminosCondiciones)
            cargarTerminosCondiciones();
    });

    const cargarTerminosCondiciones=()=>{
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/venta/link_terminocondiciones.php"
        }).then((resp)=>{
            var res = resp.data; 
            if(res[0]==="realizado") {
                setCargaTerminosCondiciones(true);
                setLinkTerminosCondiciones(res['link_terminocondiciones']);
            }
        })
    }

    const cargarSeccion = () => {
        setEstado("cargando");
        if (IsLoged) {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/cuentas/carroet2_cargar_datos.php",
            }).then((resp) => {
                var res = resp.data;
                var set_tipo = "";
                //console.log(res);
                if (res[0] === "realizado") {
                    if (
                        res["datos_cuenta"]["razon_social"].length > 0 &&
                        res["datos_cuenta"]["RUT_razon_social"].length > 0
                    ) {
                        set_tipo = "Factura";
                    } else {
                        set_tipo = "Boleta";
                    }
                    setDatosVenta({
                        ...DatosVenta,
                        tipo_despacho: "Despacho",
                        tipo: set_tipo,
                        nombre: res["datos_cuenta"]["nombre"],
                        RUT: res["datos_cuenta"]["RUT"],
                        telefono: res["datos_cuenta"]["telefono"],
                        direccion: res["datos_cuenta"]["direccion"],
                        email: res["datos_cuenta"]["email"],
                        razon_social: res["datos_cuenta"]["razon_social"],
                        RUT_razon_social:
                            res["datos_cuenta"]["RUT_razon_social"],
                    });
                    setRegion(res["datos_cuenta"]["region"]);
                    setLocalidadDisabled(false);
                    setEstado("cargado");
                    var datos = new FormData();
                    datos.append("region", res["datos_cuenta"]["region"]);
                    axios({
                        url:
                            process.env.PUBLIC_URL +
                            "/assets/api/despdn/localidades_cargar.php",
                        method: "post",
                        data: datos,
                    }).then((resp) => {
                        var res = resp.data;
                        setLocalidades(res);
                    });
                }
            });
        } else {
            setEstado("cargado");
        }
    };

    const SubmitCompra = (e) => {
        e.preventDefault();

        var campos_requeridos = 0;
        var MsjeCampoReq = "Campo requerido";
        var ErrorCampoPre = {
            nombre: { error: false, texto_error: "" },
            RUT: { error: false, texto_error: "" },
            telefono: { error: false, texto_error: "" },
            ciudad: { error: false, texto_error: "" },
            direccion: { error: false, texto_error: "" },
            email: { error: false, texto_error: "" },
            razon_social: { error: false, texto_error: "" },
            RUT_razon_social: { error: false, texto_error: "" },
            segperson_nombre: { error: false, texto_error: "" },
            segperson_RUT: { error: false, texto_error: "" },
            direccion_facturacion: { error: false, texto_error: "" }
        };

        if (DatosVenta.nombre.length <= 0) {
            ErrorCampoPre.nombre.error = true;
            ErrorCampoPre.nombre.texto_error = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.nombre.error = false;

        if (DatosVenta.RUT.length <= 0) {
            ErrorCampoPre.RUT.error = true;
            ErrorCampoPre.RUT.texto_error = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.RUT.error = false;

        if (DatosVenta.telefono.length <= 0) {
            ErrorCampoPre.telefono.error = true;
            ErrorCampoPre.telefono.texto_error = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.telefono.error = false;

        if (DatosVenta.tipo_despacho === "Despacho") {
            if (DatosVenta.ciudad.length <= 0) {
                ErrorCampoPre.ciudad.error = true;
                ErrorCampoPre.ciudad.texto_error =
                    "* Debe seleccionar una localidad para despachar *";
                campos_requeridos += 1;
            } else ErrorCampoPre.ciudad.error = false;

            if (DatosVenta.direccion.length <= 0) {
                ErrorCampoPre.direccion.error = true;
                ErrorCampoPre.direccion.texto_error = MsjeCampoReq;
                campos_requeridos += 1;
            } else ErrorCampoPre.direccion.error = false;
        }

        if (DatosVenta.email.length <= 0) {
            ErrorCampoPre.email.error = true;
            ErrorCampoPre.email.texto_error = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.email.error = false;

        /* CONDS */
        if (DatosVenta.tipo === "Factura") {
            if (DatosVenta.razon_social.length <= 0) {
                ErrorCampoPre.razon_social.error = true;
                ErrorCampoPre.razon_social.texto_error = MsjeCampoReq;
                campos_requeridos += 1;
            } else ErrorCampoPre.razon_social.error = false;

            if (DatosVenta.RUT_razon_social.length <= 0) {
                ErrorCampoPre.RUT_razon_social.error = true;
                ErrorCampoPre.RUT_razon_social.texto_error = MsjeCampoReq;
                campos_requeridos += 1;
            } else ErrorCampoPre.RUT_razon_social.error = false;

            if (DatosVenta.direccion_facturacion.length <= 0) {
                ErrorCampoPre.direccion_facturacion.error = true;
                ErrorCampoPre.direccion_facturacion.texto_error = MsjeCampoReq;
                campos_requeridos += 1;
            } else ErrorCampoPre.direccion_facturacion.error = false;
        }
        if (DatosVenta.segperson === true) {
            if (DatosVenta.segperson_RUT.length <= 0) {
                ErrorCampoPre.segperson_RUT.error = true;
                ErrorCampoPre.segperson_RUT.texto_error = MsjeCampoReq;
                campos_requeridos += 1;
            } else ErrorCampoPre.segperson_RUT.error = false;

            if (DatosVenta.segperson_nombre.length <= 0) {
                ErrorCampoPre.segperson_nombre.error = true;
                ErrorCampoPre.segperson_nombre.texto_error = MsjeCampoReq;
                campos_requeridos += 1;
            } else ErrorCampoPre.segperson_nombre.error = false;
        }

        /* Terminos y Condiciones */
        if(!CheckTerminosCondiciones){
            //ErrorTerminoCondiciones.error=true;
            //ErrorTerminoCondiciones.texto="Debe aceptar los términos y condiciones para generar la venta.";
            setErrorTerminoCondiciones({
                error:true ,
                texto:"Debe aceptar los términos y condiciones para generar la compra."
            })
            campos_requeridos+=1
        }else ErrorTerminoCondiciones.error = false;
        /* FIN Terminos y Condiciones */

        if(DatosVenta.tipo_despacho.length<=0){
            campos_requeridos+=1;
            Swal.fire({
                title:"Campos Requeridos",
                html:"Debe <b>seleccionar si el pedido será retirado en tienda, o enviado vía despacho</b>.",
                icon:"warning"
            })
        }

        if (campos_requeridos > 0) {
            setErrorCampos(ErrorCampoPre);
            return false;
        }

        if (EstadoForm.Submited) {
            return false;
        } else {
            /* Des Realizar Submit */
            if (
                DatosNoSubmit.productos_no_despachables.length > 0 &&
                DatosVenta.tipo_despacho === "Despacho"
            ) {
                var html_body = (
                    <Typography>
                        Estimado cliente, ha seleccionado despacho a domicilio,
                        sin embargo,{" "}
                        <b>
                            el carro de compras contiene productos{" "}
                            <u>no disponible para despacho</u>
                        </b>
                        .<br></br>
                        <br></br>
                        <b className="text-danger">
                            Si continúa, estos productos deberá retirarlos en
                            sucursal.
                        </b>
                    </Typography>
                );
                Swal.fire({
                    title: "Despacho a domicilio",
                    html: html_body,
                    icon: "warning",
                    confirmButtonColor: "#C82333",
                    confirmButtonText: "Confirmar",
                    cancelButtonColor: EstilosGlobales.colorSecundario,
                    cancelButtonText: "Cancelar",
                    showCancelButton: true,
                }).then((result) => {
                    if (result.value) {
                        /* SUBMIT */
                        setEstadoForm({
                            Submited: true,
                            action: "",
                        });
                        axios({
                            url:
                                process.env.PUBLIC_URL +
                                "/assets/api/venta_submit_info.php",
                        }).then((resp) => {
                            var res = resp.data;
                            //console.log(res);
                            if (res[0] === "realizado") {
                                setEstadoForm({
                                    Submited: true,
                                    action: res["DIR_PAGINA"],
                                });
                                e.target.submit();
                            }
                        });
                    }
                });
            } else {
                /* SUBMIT */
                setEstadoForm({
                    Submited: true,
                    action: "",
                });
                axios({
                    url:
                        process.env.PUBLIC_URL +
                        "/assets/api/venta/venta_submit_info.php",
                }).then((resp) => {
                    var res = resp.data;
                    //console.log(res);
                    if (res[0] === "realizado") {
                        setEstadoForm({
                            Submited: true,
                            action: res["DIR_PAGINA"],
                        });
                        e.target.submit();
                    }
                });
            }
            /*FIN Des Realizar Submit */
        }
        /*         e.currentTarget.submit();
         */
    };

    return (
        <div className="position-relative">
            <Ruta
                ruta={[
                    { nombre: "Inicio", ruta: "/" },
                    { nombre: "Carro de Compras", ruta: "/carro" },
                    { nombre: "Procesar Compra" },
                ]}
            ></Ruta>

            <form
                method="post"
                action={EstadoForm.action}
                onSubmit={(e) => {
                    SubmitCompra(e);
                }}
            >
                <div
                    className="bg-light"
                    style={{ minHeight: window.innerHeight * 0.7 }}
                >
                    {Estado === "cargando" || Estado === "inicio" ? (
                        <LinearProgressMine></LinearProgressMine>
                    ) : (
                        ""
                    )}
                    <div className="container py-4">
                        <Titulo titulo="Procesar Compra"></Titulo>
                        <div className="row">
                            <div className="col-md-8 py-2 px-0 mt-3">
                                <FormularioCarroEt2
                                    ErrorCamposPadre={{
                                        ErrorCampos,
                                        setErrorCampos,
                                    }}
                                    DatosVentaPadre={{
                                        DatosVenta,
                                        setDatosVenta,
                                    }}
                                    DatosNoSubmitPadre={{
                                        DatosNoSubmit,
                                        setDatosNoSubmit,
                                    }}
                                    Region={{ Region, setRegion }}
                                    LocalidadDisabled={{
                                        LocalidadDisabled,
                                        setLocalidadDisabled,
                                    }}
                                    Localidades={{
                                        Localidades,
                                        setLocalidades,
                                    }}
                                ></FormularioCarroEt2>
                            </div>
                            <div className="col-md-4 px-2 mt-3">
                                <div className="bg-white shadow-sm border py-4 px-5">
                                    <div className="d-flex flex-row justify-content-end">
                                        <Link to="/carro">
                                            <Typography variant="caption">
                                                Editar Carro
                                            </Typography>
                                        </Link>
                                    </div>
                                    <Titulo titulo="Resumen"></Titulo>
                                    <Typography
                                        variant="body2"
                                        className="text-secondary pt-2 mt-3 font-weight-bold"
                                    >
                                        SUBTOTAL (
                                        {DatosCarro.cantidad_productos})
                                    </Typography>
                                    <Typography
                                        style={{ color: EstilosGlobales.colorSecundario }}
                                        variant="h6"
                                        className="font-weight-bold text-right"
                                    >
                                        $
                                        {number_format(
                                            DatosCarro.total,
                                            0,
                                            ",",
                                            "."
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className="text-secondary pt-2 mt-3 font-weight-bold"
                                    >
                                        ENVÍO (
                                        {DatosNoSubmit.peso_despacho + " Kgs."})
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="text-dark font-weight-bold text-right"
                                    >
                                        $
                                        {number_format(
                                            DatosNoSubmit.total_despacho,
                                            0,
                                            ",",
                                            "."
                                        )}
                                    </Typography>
                                    {
                                        DatosNoSubmit.plazo_despacho>0 ?
                                        <Typography
                                            variant="body2"
                                            className="pt-2 mt-3 font-weight-bold"
                                            style={{color:EstilosGlobales.colorPrimario}}
                                        >
                                            Plazo Estimado {DatosNoSubmit.plazo_despacho} Días
                                            </Typography>:""

                                    }
                                    <hr></hr>
                                    {DatosNoSubmit.plazo_despacho.length > 0 ? (
                                        <div>
                                            <Typography
                                                className="font-weight-bold"
                                                style={{ color: EstilosGlobales.colorPrimario }}
                                            >
                                                {DatosNoSubmit.plazo_despacho}
                                            </Typography>
                                            <hr></hr>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <Typography
                                        variant="h6"
                                        className="text-dark pt-2 font-weight-bold"
                                    >
                                        TOTAL
                                    </Typography>
                                    <Typography
                                        style={{ color: EstilosGlobales.colorPrimario }}
                                        variant="h5"
                                        className="font-weight-bold text-right"
                                    >
                                        $
                                        {number_format(
                                            parseInt(DatosCarro.total) +
                                                parseInt(
                                                    DatosNoSubmit.total_despacho
                                                ),
                                            0,
                                            ",",
                                            "."
                                        )}
                                    </Typography>
                                    <div className="mt-2">
                                        {
                                            CargaTerminosCondiciones?
                                                <div className="d-flex flex-row">
                                                    <Checkbox
                                                        checked={CheckTerminosCondiciones}
                                                        onChange={(e)=>{
                                                            setCheckTerminosCondiciones(!CheckTerminosCondiciones); 
                                                        }}
                                                        name="CheckTerminosCondiciones"
                                                        color="primary"
                                                    />
                                                    <Typography variant="body2">
                                                        Acepto los <a target="_blank" rel="noreferrer" href={LinkTerminosCondiciones}>términos y condiciones de garantía y retracto.</a>
                                                    </Typography>
                                                </div>
                                                :""
                                        }
                                        {
                                            ErrorTerminoCondiciones.error?
                                            <Typography variant="body2" className="text-danger font-weight-bold">
                                                {ErrorTerminoCondiciones.texto}
                                            </Typography>
                                            :""
                                        }
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button
                                            type="submit"
                                            style={{
                                                backgroundColor: EstilosGlobales.colorPrimario,
                                            }}
                                            className="font-weight-bold text-white py-3"
                                            variant="contained"
                                            size="large"
                                        >
                                            Pagar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {EstadoForm.Submited ? (
                <div
                    className="w-100 h-100 position-absolute"
                    style={{ top: "0px", left: "0px", zIndex: "5000" }}
                >
                    <div
                        className="position-relative row w-100 h-100 bg-white justify-content-center align-items-center"
                        style={{ opacity: "0.7" }}
                    >
                        <div className="d-flex flex-column align-items-center">
                            <CircularProgress
                                style={{ color: EstilosGlobales.colorPrimario }}
                            ></CircularProgress>
                            <Typography
                                className="font-weight-bold"
                                variant="h6"
                            >
                                Cargando...
                            </Typography>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
