import { useState, useEffect } from "react";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { Typography } from "@material-ui/core";
import PostBlog from "./PostBlog";
import SubPostBlog from "./SubPostBlog";
import { useParams,useHistory} from "react-router-dom";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import EstilosGlobales from '../../toremp/EstilosGlobales.jsx'
//META
import MetaData from '../../context/MetaData'

export default function ContenidoBlog() {
    let { categoria_blog_id } = useParams();
    let history = useHistory();
    const [Rutas, setRutas] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Blog" },
    ]);
    const [DatosCategoriaBlog, setDatosCategoriaBlog] = useState({
        nombre: "",
        pretitulo: "",
        titulo: "",
        subtitulo: "",
        imagen: "",
    });

    const [Posts, setPosts] = useState([]);

    const [SubPosts, setSubPosts] = useState([]);

    const [Cargado, setCargado] = useState(false);

    useEffect(() => {
        const cargarDatosCategoriaBlog = () => {
            var datos = new FormData();
            datos.append("categoria_blog_id", categoria_blog_id);
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/blog/cargar_datos_categoria_blog.php",
                method: "post",
                data: datos,
            }).then((resp) => {
                var res = resp.data;
                if (res[0] === "realizado") {
                    setDatosCategoriaBlog(res["datos_categoria_blog"]);
                    setCargado(true);
                    setRutas([
                        { nombre: "Inicio", ruta: "/" },
                        { nombre: "Blog" },
                        { nombre: res['datos_categoria_blog']['nombre'] },
                    ])
                } else {
                    //Redirect
                    history.push("/");
                }
            });
        };

        const cargarPostsCategoriaBlog = () => {
            var datos = new FormData();
            datos.append("categoria_blog_id", categoria_blog_id);
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/blog/cargar_posts_categoria_blog.php",
                method: "post",
                data: datos,
            }).then((resp) => {
                var res = resp.data;
                if (res[0] === "realizado") {
                    setPosts(res["posts"]);
                    setSubPosts(res["subposts"]);
                } else {
                    setPosts([]);
                    setSubPosts([]);
                }
            });
        };
        setCargado(false);
        cargarDatosCategoriaBlog();
        cargarPostsCategoriaBlog();
    }, [categoria_blog_id,history]);

    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div
                className="bg-light"
                style={{ minHeight: window.innerHeight * 0.6 }}
            >
                {Cargado ? (
                    <div className="container-fluid px-0">
                        <MetaData titulo={DatosCategoriaBlog.titulo}/>
                        <div
                            className="position-relative overflow-hidden w-100"
                            style={{ minHeight: "280px" }}
                        >
                            <img
                                className="img-fluid position-absolute"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/imagenes/blog/categorias/" +
                                    DatosCategoriaBlog.imagen
                                }
                                alt="..."
                                style={{
                                    zIndex: "5",
                                    transform: "translate(0px,-50%)",
                                    top: "50%",
                                }}
                            />
                            <div
                                className="position-absolute h-100 w-100"
                                style={{
                                    zIndex: "10",
                                    backgroundColor: EstilosGlobales.Dark,
                                    opacity: "0.3",
                                }}
                            ></div>
                            <div
                                className="position-absolute"
                                style={{
                                    top: "20%",
                                    left: "20%",
                                    zIndex: "30",
                                }}
                            >
                                <Typography
                                    className="text-white"
                                    variant="body2"
                                >
                                    {DatosCategoriaBlog.pretitulo}
                                </Typography>
                                <Typography
                                    className="text-white font-weight-bold"
                                    variant="h3"
                                >
                                    {DatosCategoriaBlog.titulo}
                                </Typography>
                                <Typography className="text-white" variant="h5">
                                    {DatosCategoriaBlog.subtitulo}
                                </Typography>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="container-fluid px-0"
                        style={{ minHeight: "280px" }}
                    >
                        <Skeleton height={280} variant="rect"></Skeleton>
                    </div>
                )}
                <div className="container">
                    {Posts.length > 0 ? (
                        <div className="row py-4 px-2">
                            {Posts.map((post, index) => {
                                return (
                                    <PostBlog
                                        key={index}
                                        DatosPost={post}
                                        className="col-md-6 my-2"
                                    ></PostBlog>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="row py-4 px-2 justify-content-center">
                            <Typography variant="h6">
                                Sin Publicaciones
                            </Typography>
                        </div>
                    )}
                    {SubPosts.length > 0 ? (
                        <>
                            <div className="py-2">
                                <Titulo>Destacado</Titulo>
                            </div>
                            <div className="row py-4 px-2">
                                {SubPosts.map((subpost, index) => {
                                    return (
                                        <SubPostBlog
                                            key={index}
                                            DatosPost={subpost}
                                            className="col-md-3 my-2"
                                        ></SubPostBlog>
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
    
}
